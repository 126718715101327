@import "./variables.scss";

.blog-post-page {
    display: grid;
    width: 100%;
    padding: 2rem .5rem;
    overflow: hidden;
    .specs {
        display: none;
    }
    .blog-main-content {
        width: 100%;
    }
    .blog-post-image {
        float: left;
        width: 100%;
        img {
            width: 100%;
            height: 100%;
        }
    }

    h1 {
        font-size: 1.5rem;
    }
    p {
        width: auto;
        text-wrap: wrap;
        word-wrap: break-word;
    }
}


@include responsive(small) {
    .blog-post-page {
        display: grid;
        grid-template-columns: 1fr 2fr;
        width: 100%;
        .specs {
            display: grid;
            .label {
                font-weight: bold;
                margin-right: .5rem;
            }
           .blog-post-back-link {
            display: flex;
            align-items: center;
            align-content: center;
            width: max-content;
            color: $primary-color;
            text-decoration: none;
            font-weight: bold;
            height: 2rem;
            &:hover {
                text-decoration: underline;
            }
        }
            .blog-post-machine-link {
                display: flex;
                align-items: center;
                font-size: 1rem;
                background: $primary-color;
                color: white;
                padding: .5rem 1rem;
                width: max-content;
                font-weight: bold;
                margin-top: 1rem;
                &:hover {
                    background: darken($primary-color, 10%);
                }
            }
        }
        .blog-main-content {
            width: 100%;
            padding: 0rem 1rem;
            border-left: 1px solid #e0e0e0;
        }
        .blog-post-image {
            float: left;
            width: 100%;
            margin-right: 10px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .blog-heading-details {
            padding-bottom: 1rem;
        }
        p {
            line-height: 1.65rem;
            text-wrap: wrap;
            word-break: keep-all;
            word-wrap: break-word;
            padding-bottom: .5rem;
        }
    }
}

@include responsive(medium) {
    .blog-post-page {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 2fr 1fr;
        .specs {
            display: grid;


        }
        .blog-main-content {
            width: 100%;
            padding: 0rem 1rem;
            border-left: 1px solid #e0e0e0;
            border-right: 1px solid #e0e0e0;
        }
        .blog-post-image {
            float: left;
            width: 40%;
            img {
                width: 100%;
                height: 100%;
            }
        
        }
        .blog-heading-details {
            padding-bottom: 1rem;
        }
        p {
            line-height: 1.65rem;
        }
        h1 {
            font-size: 2rem;
        }
    }
}