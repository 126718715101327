@import "./variables.scss";

.handcrafted-banner {
    display: flex;
    position: relative;
    height: 400px;
    overflow-y: hidden;
    width: 100%;
    h2 {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        color: white;
        height: 100%;
        width: 100%;
        font-size: 4.25rem;
        text-align: center;
    }
    img {
        object-fit: cover;
        height: 100%;
    }
}

@include responsive(small) {


}


@include responsive(medium) {
    .handcrafted-banner {
        h2 {
            display: flex;
            justify-content: left;
            align-items: center;
            position: absolute;
            color: white;
            font-size: 6rem;
            text-align: left;
            width: 50%;
            padding: $notepad-width-padding;
        }
        img {
            object-fit: cover;
            height: 100%;
        }
    }
}


@include responsive(large) {
    .handcrafted-banner {
        h2 {
            padding: $desktop-width-padding;
            font-size: 8rem;
        }
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    
}