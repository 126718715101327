.mobile-navigation {
	display: flex;
	width: 100%;
	align-items: center;
	position: fixed;
	z-index: 2;
	align-content: center;
	height: 4rem;
	justify-content: space-between;
	background: $factorycatred;
	padding: $mobile-width-padding;
	svg {
		color: white;
		font-size: 2.5rem;
		margin-right: 1rem;
		cursor: pointer;
	}
	img {
		object-fit: contain;
		width: 200px;
		height: max-content;
		cursor: pointer;
	}
}

.mobile-menu {
	display: flex;
	flex-direction: column;
	position: fixed;
	height: 100vh;
	width: 100vw;
	background: rgba($color: #000000, $alpha: 0.95);
	z-index: 3;
	ul {
		display: flex;
		flex-direction: column;
		padding: 1rem 1rem;
		list-style-type: none;
		ul {
			color: white;
			padding: 0.125rem 0.5rem;
			li {
				a {
					font-size: 1rem;
					color: #ccc;
					width: 100%;
					height: 100%;
					font-weight: normal;
					&:hover {
						color: $factorycatred;
					}
				}
			}
		}
		li {
			width: 100%;
			padding: 1rem 0rem;
			border-bottom: 1px solid #333;
			&.dropdown {
				display: flex;
				justify-content: space-between;
				align-items: center;
				color: white;
				cursor: pointer;
				svg {
					font-size: 1.2rem;
				}
			}
			a {
				color: white;
				text-decoration: none;
				font-weight: 500;
				width: 100%;
				height: 100%;
				&:hover {
					color: #ddd;
				}
			}
		}
	}
}
.menu-header {
	display: flex;
	width: 100%;
	align-items: center;
	z-index: 2;
	align-content: center;
	height: 3.5rem;
	justify-content: space-between;
	background: $factorycatred;
	padding: $mobile-width-padding;
	svg {
		color: white;
		font-size: 1.75rem;
		margin-right: 1rem;
		cursor: pointer;
	}
	img {
		object-fit: contain;
		width: 150px;
		height: max-content;
		cursor: pointer;
	}
}
.mobile-menu-wrapper {
	display: flex;
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 9;
	transition: ease-in-out 0.25s;
	&.open {
		transform: translateX(0%);
	}
	&.close {
		transform: translateX(-120%);
	}
}

@include responsive(medium) {
}
@include responsive(large) {
	.mobile-navigation,
	.mobile-menu-wrapper {
		display: none;
	}
}
