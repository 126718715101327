@import "./variables.scss";

.parts-page {
    display: grid;
    margin: auto;
    column-gap: 2rem;
    justify-content: flex-start;
    max-width: 600px;
    width: 100%;
    overflow: hidden;
    padding-top: 4rem;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-bottom: 1rem;
    .parts-page_body {
        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
.parts-page_header {
    display: grid;
    margin-bottom: 1rem;
    p {
        color: #555;
        margin-bottom: 1rem;
    }
    .part-number {
        margin-top: .5rem;
        font-size: 1rem;
        font-weight: bold;
        color: #666;
    }
    .part-description {
        font-size: 1.25rem;
        font-weight: bold;
    }
    .part-link {
        display: flex;
        column-gap: 2rem;
        margin-top: 1rem;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        height: 2.5rem;
        font-weight: bolder;
        border-radius: .25rem;
        background: black;
        color: white;
        &:hover {
            background: $factorycatred;
        }
    }
    .back-link {
        margin-top: 1rem;
        text-align: center;
        color: $factorycatred;
    }
    ul {
        li {
            font-size: .8rem;
        }
    }
}

}

@include responsive(medium) {
    .parts-page {
        display: grid;
        .parts-page_body {
            display: grid;
            column-gap: 1rem;
        }
    }
}