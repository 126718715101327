@import "./variables.scss";

.desktop-drowndown-menu {
	display: none;
}

@include responsive("medium") {
	.desktop-drowndown-menu {
		display: flex;
		position: absolute;
		width: 100%;
		z-index: 9000;
	}
	.products-navigation-content-wrapper {
		display: grid;
		grid-template-columns: auto 5fr;
		background: white;
		width: 100%;
		height: 400px;
		overflow: hidden;
		// Products
		.left-product-links {
			display: flex;
			flex-direction: column;
			width: 100%;
			ul {
				display: flex;
				flex-direction: column;
				list-style-type: none;
				justify-content: space-between;
				height: max-content;
				overflow: hidden;
				height: 100%;
				padding: 0.5rem;
				row-gap: 0.5rem;
				align-items: flex-start;
				align-content: flex-start;
				li {
					display: grid;
					height: min-content;
					align-items: flex-start;
					align-content: flex-start;
					padding: 0.5rem;
					width: 100%;
					height: 100%;
					column-gap: 1rem;
					transition: all 0.2s ease-in-out;
					background: conic-gradient(
						from 360deg at -20% 40%,
						#000000,
						#060606,
						#121212,
						#1d1d1d,
						#282828,
						#323232,
						#3d3d3d,
						#484848,
						#535353,
						#5e5e5e,
						#696969,
						#747474
					);
					box-shadow: 0px 4px 8px rgba($color: #000000, $alpha: 0.5);
					.products-link {
						display: flex;
						justify-content: space-between;
						column-gap: 1rem;
						a {
							font-size: 1rem;
							font-weight: 600;
							transition: all 0.2s ease-in-out;
							color: $factorycatred;
						}

						svg {
							transition: ease-in-out 200ms;
							color: $factorycatred;
						}
					}
					&.selected {
						background: linear-gradient(
							180deg,
							#000000,
							#060606,
							#0d0d0d,
							#111111,
							#151515,
							#191919,
							#1c1c1c,
							#202020
						);
						cursor: pointer;
						a {
							color: #fff;
						}
						svg {
							color: #fff;
						}
					}
					&:last-child {
						border-right: none;
					}
					&:hover {
						background: linear-gradient(
							180deg,
							#000000,
							#060606,
							#0d0d0d,
							#111111,
							#151515,
							#191919,
							#1c1c1c,
							#202020
						);
						cursor: pointer;
						a {
							color: #fff;
						}

						svg {
							color: #fff;
						}
					}

					&.title {
						background: $factorycatred;
						color: rgb(35, 35, 35);
						font-weight: bold;
					}
					a {
						transition: all 0.2s ease-in-out;
						text-decoration: none;
						font-weight: bold;
						color: rgb(255, 255, 255);
						text-decoration: none;
					}
					p {
						margin: 0;
						font-size: 0.8rem;
						color: #888;
						font-style: italic;
						padding: 0rem;
						border-radius: 0.5rem;
					}
				}
			}
		}
		.right-product-column-wrapper {
			display: grid;
			grid-template-rows: auto 1fr;
			padding: 1rem;
			.selected-sublink {
				display: grid;
				width: 100%;
				font-size: 1.25rem;
				letter-spacing: 0.15rem;
				text-transform: uppercase;
				color: #333;
				font-weight: bold;
				align-content: center;
				border-bottom: 2px solid #b7b7b7;
			}
			.test {
				font-size: 0.9rem;
				text-transform: none;
				color: #aaa;
				letter-spacing: normal;
				padding: 0.5rem 0rem;
				width: 95%;
			}
			p {
				margin: 0;
				font-size: 0.8rem;
				color: #888;
				font-style: italic;
				padding: 0rem;
				border-radius: 0.5rem;
				font-weight: normal;
			}
		}
		.right-product-content {
			display: flex;
			justify-content: center;
			justify-items: center;
			align-items: center;
			align-content: center;
			column-gap: 2rem;
			padding: 1rem 1rem;
			height: 100%;
			overflow: hidden;
			a {
				text-decoration: none;
			}
			.machine {
				display: grid;
				grid-template-rows: auto 1fr;
				justify-content: center;
				justify-items: center;
				align-content: center;
				align-items: flex-start;
				transition: all 0.2s ease-in-out;
				height: max-content;
				width: 100%;
				&:hover {
					transform: scale(1.05);
					h3 {
						color: $factorycatred;
					}
				}
				h3 {
					color: black;
					font-weight: bold;
					text-align: center;
					text-decoration: none;
				}
				img {
					display: grid;
					width: 85%;
					object-fit: contain;
					height: min-content;
				}
			}
		}
	}
	.support-navigation-content-wrapper {
		display: grid;
		grid-template-columns: 5fr 2fr;
		background: #fff;
		width: 100%;
		height: 400px;
		overflow: hidden;
		.support-main-section {
			display: grid;
			grid-template-rows: auto 1fr;
			align-items: flex-start;
			.support-title-head {
				display: grid;
				padding: 1rem;
				border-bottom: 2px solid #b7b7b7;
				height: max-content;
				h3 {
					display: grid;
					width: 100%;
					font-size: 1.25rem;
					letter-spacing: 0.15rem;
					font-weight: bold;
					text-transform: uppercase;
					color: #333;
					align-content: center;
					padding-bottom: 0.25rem;
				}
				p {
					margin: 0;
					font-size: 0.8rem;
					color: #888;
					font-style: italic;
					padding: 0rem;
					border-radius: 0.5rem;
				}
			}
		}
		.support-left-subnavigation {
			display: grid;
			grid-template-columns: 2fr 2fr 2fr;
			grid-template-rows: 1fr 1fr;
			grid-template-areas:
				"link link link"
				"link link link";
			list-style-type: none;
			align-content: flex-start;
			align-items: flex-start;
			overflow: hidden;
			column-gap: 1rem;
			row-gap: 1rem;
			height: 100%;
			li {
				display: grid;
				align-content: flex-start;
				height: max-content;
				justify-content: flex-start;
				row-gap: 0.5rem;
				height: 100%;
				box-shadow: 0px 4px 0.5rem rgba($color: #000000, $alpha: 0.25);
				transition: all ease-in-out 200ms;
				background: conic-gradient(
					from 360deg at -20% 40%,
					#000000,
					#060606,
					#121212,
					#1d1d1d,
					#282828,
					#323232,
					#3d3d3d,
					#484848,
					#535353,
					#5e5e5e,
					#696969,
					#747474
				);
				a {
					text-decoration: none;
				}
				.card-wrapper {
					display: grid;
					grid-template-areas:
						"icons title"
						"icons description";
					padding: 0.5rem;
					row-gap: 0.125rem;
					form {
						grid-area: form;
					}
					svg {
						grid-area: icons;
						text-decoration: none;
						transition: all 0.2s ease-in-out;
						color: $factorycatred;
						margin-right: 0.5rem;
					}
					h3 {
						grid-area: title;
						font-size: 1rem;
						font-weight: 600;
						transition: all 0.2s ease-in-out;
						color: $factorycatred;
					}
					p {
						font-size: 0.8rem;
						color: #888;
						font-style: italic;
						grid-area: description;
						padding: 0rem;
						border-radius: 0.5rem;
					}
				}
				&:hover {
					cursor: pointer;
					svg,
					h3 {
						color: white;
					}
				}
			}
			padding: 1rem;
		}
		.serial-search-section {
			display: grid;
			align-items: flex-start;
			align-content: flex-start;
			padding: 1rem;
			width: 100%;
			height: 100%;
			background: conic-gradient(
				from 360deg at -20% 40%,
				#000000,
				#060606,
				#121212,
				#1d1d1d,
				#282828,
				#323232,
				#3d3d3d,
				#484848,
				#535353,
				#5e5e5e,
				#696969,
				#747474
			);
			.search-card-title-wrapper {
				padding: 0rem 0rem;
				.card-title {
					display: grid;
					grid-template-areas:
						"icons title"
						"icons description";
					width: 100%;
					align-items: flex-start;
					align-content: flex-start;
					justify-content: flex-start;
					svg {
						grid-area: icons;
						text-decoration: none;
						transition: all 0.2s ease-in-out;
						color: $factorycatred;
						margin-right: 0.5rem;
					}
					h3 {
						grid-area: title;
						color: rgb(35, 35, 35);
						font-weight: bold;
						color: $factorycatred;
					}
					p {
						font-size: 0.85rem;
						color: #888;
						font-style: italic;
						grid-area: description;
					}
				}
			}
			form {
				display: grid;
				grid-template-columns: 4fr 1fr;
				column-gap: 1rem;
				row-gap: 1rem;
				padding: 0rem 1rem;
				input {
					padding: 0.75rem;
					border-radius: 0.25rem;
				}
				button {
					background-color: $factorycatred;
					border: none;
					border-radius: 0.25rem;
					padding: 0.65rem 1.5rem;
					color: white;
				}
			}
		}
	}
	.gallery-navigation-content-wrapper {
		display: grid;
		grid-template-columns: auto 1fr;
		height: 400px;
		background: #111;
		width: 100%;
		overflow: visible;
		.gallery-navigation-left {
			display: grid;
			row-gap: 1rem;
			width: 100%;
			padding: 1rem;
			align-content: flex-start;
			.navigation-gallery-title-section {
				display: grid;
				border-bottom: 2px solid $factorycatred;
				padding-bottom: 0.75rem;
				row-gap: 0.5rem;
				h2 {
					color: white;
					span {
						color: $factorycatred;
					}
					.model {
						color: white;
					}
					.type {
						color: $factorycatred;
						font-weight: lighter;
					}
				}
				p {
					color: #ccc;
					padding: 0rem;
				}
			}
			.navigation-gallery-content-section {
				display: grid;
				row-gap: 1rem;
				padding-bottom: 1rem;
				align-content: flex-start;
				border-bottom: 1px solid #333;
				p {
					color: #ccc;
					padding: 0rem;
				}
			}
			.navigation-gallery-link {
				background: transparent;
				border: 1px solid $factorycatred;
				padding: 0.5rem 1.5rem;
				width: max-content;
				color: white;
				text-decoration: none;
				border-radius: 0.25rem;
				transition: all 0.2s ease-in-out;
				&:hover {
					background: $factorycatred;
					cursor: pointer;
				}
			}
			// .navigation-gallery-footer-section {
			//     display: grid;
			//     padding: 1rem 0rem;
			//     row-gap: 1rem;
			//     align-content: flex-start;
			//     a {
			//         background: transparent;
			//         border: 1px solid $factorycatred;
			//         padding: .5rem 1.5rem;
			//         width: max-content;
			//         color: white;
			//         border-radius: .25rem;
			//         transition: all .2s ease-in-out;
			//         text-decoration: none;
			//         &:hover {
			//             background: $factorycatred;
			//             cursor: pointer;
			//         }
			//     }
			// }
		}
		.gallery-navigation-right {
			display: grid;
			row-gap: 1rem;
			align-content: flex-start;
			width: 100%;
			height: 400px;
			padding: 1rem;
			.gallery-navigation-images-wrapper {
				display: grid;
				justify-content: flex-end;
				column-gap: 0.25rem;
				row-gap: 0.25rem;
				height: 400px;
				grid-template-areas:
					"image1 image2"
					"image3 image4";
				grid-template-rows: calc(200px - 1rem) calc(200px - 1rem);
				img {
					display: grid;
					height: 100%;
					width: 100%;
					object-fit: contain;
					&:nth-child(1) {
						grid-area: image1;
					}
					&:nth-child(2) {
						grid-area: image2;
					}

					&:nth-child(3) {
						grid-area: image3;
					}

					&:nth-child(4) {
						grid-area: image4;
					}
				}
			}
		}
	}
	.parts-shop-navigation-content-wrapper {
		display: grid;
		grid-template-columns: 5fr 2fr;
		background: #fff;
		width: 100%;
		height: 100%;
		overflow: hidden;
		.parts-shop-navigation-left {
			display: grid;
			width: 100%;
			padding: 1rem;
			row-gap: 1rem;
			grid-template-rows: auto 1fr;
			.title-section {
				display: grid;
				height: max-content;
				border-bottom: 2px solid #b7b7b7;
				padding-bottom: 0.75rem;
				h2 {
					display: grid;
					width: 100%;
					font-size: 1.25rem;
					letter-spacing: 0.15rem;
					font-weight: bold;
					text-transform: uppercase;
					color: #333;
					align-content: center;
					padding-bottom: 0.25rem;
				}
				p {
					margin: 0;
					font-size: 0.8rem;
					color: #888;
					font-style: italic;
					padding: 0rem;
					border-radius: 0.5rem;
				}
			}
			.parts-shop-navigation-results {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				grid-template-rows: 1fr 1fr;
				grid-template-areas:
					"link link link"
					"link link link";
				list-style-type: none;
				overflow: hidden;
				column-gap: 1rem;
				row-gap: 1rem;
				padding: 0rem 0rem;
				height: 100%;
				overflow: visible;
				li {
					display: grid;
					row-gap: 0.5rem;
					height: 100%;
					box-shadow: 0px 0px 0.5rem
						rgba($color: #000000, $alpha: 0.25);
					transition: all ease-in-out 200ms;
					background: conic-gradient(
						from 360deg at -20% 40%,
						#000000,
						#060606,
						#121212,
						#1d1d1d,
						#282828,
						#323232,
						#3d3d3d,
						#484848,
						#535353,
						#5e5e5e,
						#696969,
						#747474
					);
					width: 100%;
					.card-wrapper {
						display: grid;
						align-items: flex-start;
						align-content: flex-start;
						grid-template-columns: 2fr 3fr;
						height: 100%;
						padding: 0.5rem;
						column-gap: 0.5rem;
						text-decoration: none;
						img {
							display: grid;
							width: 100%;
							max-width: 110px;
							height: auto;
						}
						.card-details {
							display: grid;
							align-content: flex-start;
							text-wrap: wrap;
						}
						h3 {
							font-size: 1rem;
							font-weight: 600;
							line-height: 1rem;
							transition: all 0.2s ease-in-out;
							color: $factorycatred;
						}
						p {
							text-wrap: wrap;
							width: 100%;
							font-size: 0.8rem;
							color: #888;
							font-style: italic;
							border-radius: 0.5rem;
						}
					}
					&:hover {
						cursor: pointer;
						svg,
						h3 {
							color: white;
						}
					}
				}
			}
		}
		.parts-shop-navigation-right {
			display: grid;
			align-items: flex-start;
			align-content: flex-start;
			padding: 1rem;
			row-gap: 0.5rem;
			width: 100%;
			height: 100%;
			background: conic-gradient(
				from 360deg at -20% 40%,
				#000000,
				#060606,
				#121212,
				#1d1d1d,
				#282828,
				#323232,
				#3d3d3d,
				#484848,
				#535353,
				#5e5e5e,
				#696969,
				#747474
			);
		}
		form {
			display: grid;
			width: 100%;
			input[type="text"] {
				padding: 0.75rem;
				border-radius: 0.25rem;
			}
		}
		.title-section {
			h3 {
				grid-area: title;
				font-size: 1rem;
				font-weight: 600;
				transition: all 0.2s ease-in-out;
				color: #fff;
			}
		}
		.parts-shop-external-section {
			display: grid;
			row-gap: 1rem;
			p {
				color: #ccc;
				padding: 0rem;
				font-style: italic;
			}
			a {
				display: flex;
				background: transparent;
				width: max-content;
				color: white;
				text-decoration: none;
				border-radius: 0.25rem;
				transition: all 0.2s ease-in-out;
				text-decoration: underline;
				svg {
					margin-left: 1rem;
				}
				&:hover {
					cursor: pointer;
					color: $factorycatred;
				}
			}
		}
	}
	.login-navigation-content-wrapper {
		display: grid;
		justify-items: center;
		justify-content: center;
		align-items: center;
		align-content: center;
		grid-template-columns: 1fr;
		column-gap: 4rem;
		background: #111;
		height: 400px;
		width: 100%;
		align-content: flex-start;
		.login-background-wrapper {
			display: grid;
			height: 375px;
			max-width: 500px;
			position: relative;
			img {
				object-fit: contain;
				height: 375px;
			}
		}
		.login-wrapper {
			display: grid;
			position: absolute;
			align-items: center;
			justify-content: center;
			justify-items: center;
			width: 100%;
			height: 100%;
		}
		.login-section {
			display: grid;
			row-gap: 1rem;
			background: #000;
			border-radius: 0.25rem;
			padding: 1rem;
			box-shadow: 0px 1rem 20px rgba($color: #000000, $alpha: 0.5);
		}
		.login-button {
			display: grid;
			justify-content: center;
			text-decoration: none;
			padding: 0.75rem 1.5rem;
			background: transparent;
			border: none;
			color: white;
			font-weight: bold;
			background: $factorycatred;
			border-radius: 0.25rem;
			transition: all 0.2s ease-in-out;
			&:hover {
				background: #fff;
				color: $factorycatred;
			}
		}
		.login-navigation {
			display: grid;
			row-gap: 1rem;
			align-content: center;
			align-items: center;
			height: 400px;
			max-width: 400px;
			padding: 1rem;
			.login-title-section {
				display: grid;
				align-self: center;
				row-gap: 0.25rem;
				border-bottom: 2px solid $factorycatred;
				padding-bottom: 0.5rem;
				h2 {
					color: white;
					.model {
						color: white;
					}
					.type {
						color: $factorycatred;
						font-weight: lighter;
					}
				}
				p {
					color: #ccc;
					padding: 0rem;
				}
			}
			ul {
				display: grid;
				grid-template-columns: 1fr 1fr;
				row-gap: 0.5rem;
				column-gap: 0.5rem;
				list-style-type: none;
				color: white;
				li {
					display: grid;
					width: 100%;
					height: 100%;
					padding: 1rem;
					background: conic-gradient(
						from 360deg at -20% 40%,
						#000000,
						#060606,
						#121212,
						#1d1d1d,
						#282828,
						#323232,
						#3d3d3d,
						#484848,
						#535353,
						#5e5e5e,
						#696969,
						#747474
					);
					color: #ccc;
					p {
						color: white;
					}
				}
			}
		}
		.login-navigation-left {
			display: grid;
			row-gap: 1rem;
			padding: 1rem;
			align-content: flex-start;
			.login-title-section {
				display: grid;
				row-gap: 0.25rem;
				border-bottom: 2px solid $factorycatred;
				padding-bottom: 0.5rem;
				h2 {
					color: white;
					.model {
						color: white;
					}
					.type {
						color: $factorycatred;
						font-weight: lighter;
					}
				}
				p {
					color: #ccc;
					padding: 0rem;
				}
			}
			ul {
				display: grid;
				width: 100%;
				grid-template-columns: 1fr 1fr;
				row-gap: 0.5rem;
				column-gap: 0.5rem;
				list-style-type: none;
				color: white;
				li {
					display: grid;
					width: 100%;
					height: 100%;
					padding: 1rem;
					background: conic-gradient(
						from 360deg at -20% 40%,
						#000000,
						#060606,
						#121212,
						#1d1d1d,
						#282828,
						#323232,
						#3d3d3d,
						#484848,
						#535353,
						#5e5e5e,
						#696969,
						#747474
					);
					color: #ccc;
					font-size: 0.7rem;
					border-left: 1px solid $factorycatred;

					p {
						font-size: 0.8rem;
						color: white;
					}
				}
			}
		}
		.red {
			color: $factorycatred;
		}
	}
}

@include responsive("large") {
	.support-navigation-content-wrapper {
		height: 500px;
		.support-left-subnavigation {
			display: grid;
			grid-template-columns: 2fr 2fr 2fr;
			grid-template-rows: 1fr 1fr;
			grid-template-areas:
				"link link link"
				"link link link";
			list-style-type: none;
			align-content: flex-start;
			align-items: flex-start;
			overflow: hidden;
			column-gap: 1rem;
			row-gap: 1rem;
			height: 100%;
			li {
				.card-wrapper {
					padding: 1rem;
				}
			}
		}
	}
	.parts-shop-navigation-content-wrapper {
		height: 500px;
		.parts-shop-navigation-left {
			.parts-shop-navigation-results {
				li {
					.card-wrapper {
						display: grid;
						align-items: flex-start;
						align-content: flex-start;
						grid-template-columns: 3fr 4fr;
						height: 100%;
						padding: 0.5rem;
						column-gap: 0.5rem;
						text-decoration: none;
						img {
							display: grid;
							max-width: 165px;
							width: 100%;
							height: 100%;
						}
						.card-details {
							display: grid;
							align-content: flex-start;
							text-wrap: wrap;
						}
						h3 {
							font-size: 1rem;
							font-weight: 600;
							line-height: 1rem;
							transition: all 0.2s ease-in-out;
							color: $factorycatred;
						}
						p {
							text-wrap: wrap;
							width: 100%;
							font-size: 0.8rem;
							color: #888;
							font-style: italic;
							border-radius: 0.5rem;
						}
					}
					&:hover {
						cursor: pointer;
						svg,
						h3 {
							color: white;
						}
					}
				}
			}
		}
	}
	.login-navigation-content-wrapper {
		align-content: center;
		height: 500px;
		.login-background-wrapper {
			display: grid;
			height: 400px;
			max-width: 500px;
			position: relative;
			img {
				object-fit: contain;
				height: 375px;
			}
		}
	}
	.products-navigation-content-wrapper {
		grid-template-columns: 2fr 5fr;
		height: 500px;
		.right-product-column-wrapper {
			.test {
				width: 65%;
			}
		}
		.left-product-links {
			display: flex;
			flex-direction: column;
			width: 100%;
			padding: 0.5rem;
			ul {
				padding: 0.5rem;
				row-gap: 1rem;
				li {
					padding: 1rem;
				}
			}
		}
	}
	.gallery-navigation-content-wrapper {
		grid-template-columns: auto 1fr;
		height: 500px;
		.gallery-navigation-left {
			display: grid;
			row-gap: 1rem;
			width: 100%;
			padding: 1rem;
			align-content: flex-start;
			.navigation-gallery-title-section {
				display: grid;
				border-bottom: 2px solid $factorycatred;
				padding-bottom: 0.75rem;
				row-gap: 0.5rem;
				h2 {
					color: white;
					span {
						color: $factorycatred;
					}
					.model {
						color: white;
					}
					.type {
						color: $factorycatred;
						font-weight: lighter;
					}
				}
				p {
					color: #ccc;
					padding: 0rem;
				}
			}

			.navigation-gallery-link {
				background: transparent;
				border: 1px solid $factorycatred;
				padding: 0.5rem 1.5rem;
				width: max-content;
				color: white;
				text-decoration: none;
				border-radius: 0.25rem;
				transition: all 0.2s ease-in-out;
				&:hover {
					background: $factorycatred;
					cursor: pointer;
				}
			}
			// .navigation-gallery-footer-section {
			//     display: grid;
			//     padding: 1rem 0rem;
			//     row-gap: 1rem;
			//     align-content: flex-start;
			//     a {
			//         background: transparent;
			//         border: 1px solid $factorycatred;
			//         padding: .5rem 1.5rem;
			//         width: max-content;
			//         color: white;
			//         border-radius: .25rem;
			//         transition: all .2s ease-in-out;
			//         text-decoration: none;
			//         &:hover {
			//             background: $factorycatred;
			//             cursor: pointer;
			//         }
			//     }
			// }
		}
		.gallery-navigation-right {
			display: grid;
			row-gap: 1rem;
			align-content: flex-start;
			width: 100%;
			height: 400px;
			padding: 1rem;
			.gallery-navigation-images-wrapper {
				display: grid;
				justify-content: flex-end;
				column-gap: 0.25rem;
				row-gap: 0.25rem;
				height: 500px;
				grid-template-areas: "image1 image2 image3 image4";
				grid-template-rows: calc(500px - 2rem);
				img {
					display: grid;
					height: 100%;
					width: 100%;
					object-fit: contain;
					&:hover {
						cursor: pointer;
					}
					&:nth-child(1) {
						grid-area: image1;
					}
					&:nth-child(2) {
						grid-area: image2;
					}

					&:nth-child(3) {
						grid-area: image3;
					}

					&:nth-child(4) {
						display: none;
						grid-area: image4;
					}
				}
			}
		}
	}
}
