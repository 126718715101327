@import "./variables.scss";

.all-parts-viewer__page {
    display: grid;
    align-items: flex-start;
    align-content: flex-start;
    margin: auto;
    padding-top: 3.5rem;
    input {
        padding: .5rem;
        font-size: 1rem;
        margin-bottom: 1rem;
        width: 100%;
    }
    a {
        text-decoration: none;
        color: inherit;
    }
    ul {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: .25rem;
        li {
            display: grid;
            align-content: center;
            grid-template-columns: 2fr auto;
            background: rgba($color: #f3f3f3, $alpha: 1.0);
            align-items: center;
            column-gap: .25rem;
            padding: .5rem 1rem;
            cursor: pointer;
            &:hover {
                background: rgba($color: #c9c9c9, $alpha: .75);
                .view-part-btn {
                    background: red;
                    color: black;
                }
            }
            .view-part-btn {
                background: black;
                color: white;
                padding: .5rem 1rem;
                font-size: .95rem;
            }
            p {
                padding: .25rem 0rem;
            }
            .part-details {
                display: grid;
                grid-template-columns: 1fr;
                .partNumber {
                    font-weight: bold;
                    font-size: .9rem;
                }
            }
            .part-link {
                text-decoration: none;
                color: black;
                margin-right: .65rem;
            }
        }

    }
}

@include responsive(medium) {
    .all-parts-viewer__page {
        input {
            padding: .5rem;
            font-size: 1rem;
            margin-bottom: 1rem;
            width: 50%;
        } 
        ul {
            grid-template-columns: 1fr 1fr;
            column-gap: 2rem;
        }
    }
}

@include responsive(large) {
    .all-parts-viewer__page {
        ul {
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 2rem;
        }
    }
}