@import "./variables.scss";

footer {
	display: grid;
	align-self: flex-end;
	flex-wrap: wrap;
	background: rgba($color: #000000, $alpha: 0.85);
	color: white;
	padding: $mobile-width-padding;
	padding-top: 1rem;
	padding-bottom: 10rem;
	row-gap: 1rem;
	column-gap: 1rem;
	ul {
		list-style-type: none;
		li {
			height: 1.75rem;
			&.heading {
				font-weight: bold;
				margin-bottom: 0.5rem;
			}
			a {
				text-decoration: none;
				color: rgba($color: #ffffff, $alpha: 0.75);
				transition: ease-in-out 200ms;
				&:hover {
					color: white;
				}
			}
		}
	}
}
@include responsive(small) {
	footer {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: $notepad-width-padding;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}
@include responsive(medium) {
	footer {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: $notepad-width-padding;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}
@include responsive(large) {
	footer {
		padding: $desktop-width-padding;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}
