@import "../variables.scss";

.home-two-header-banner {
	width: 100%;
	height: calc(600px * 0.75);
	position: relative;
	overflow: hidden;
	.image-container {
		display: flex;
		width: 100%;
		height: 100%;
		transition: transform 0.5s ease-in-out;
	}
	.slide {
		display: flex;
		height: 100%;
		width: 100%;
		object-fit: cover;
		position: relative;
		img {
			width: 100vw;
			height: calc(600px * 0.75);
			object-fit: cover;
		}
		.content-wrapper {
			display: flex;
			position: absolute;
			width: 100%;
			padding: 0.5rem;
			top: 5.5rem;
			padding: $mobile-width-padding;
			h2 {
				color: white;
				font-size: 1.25rem;
				text-align: center;
			}
			p {
				color: white;
				font-size: 1rem;
				padding: 0rem;
				text-align: center;
			}
			.content {
				display: flex;
				flex-direction: column;
				row-gap: 1rem;
				text-align: left;
				background: rgba($color: #000000, $alpha: 0.65);
				padding: 1rem;
				.button-wrapper {
					display: flex;
					flex-direction: column;
					row-gap: 0.5rem;
					a {
						text-align: center;
					}
				}
			}
		}
	}
}

.button-container {
	display: flex;
	position: absolute;
	column-gap: 0.5rem;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	button {
		background: rgba($color: $factorycatred, $alpha: 0.75);
		border: none;
		padding: 0.35rem 1.25rem;
		text-align: center;
		&:hover {
			background-color: white;
		}
		&.active {
			background: rgb(198, 198, 198);
		}
	}
}

@include responsive(small) {
	.home-two-header-banner {
		.slide {
			display: flex;
			height: 100%;
			width: 100%;
			object-fit: cover;
			position: relative;
			img {
				width: 100vw;
				object-fit: cover;
				object-position: center;
			}
			.content-wrapper {
				padding: $notepad-width-padding;
				&.bottom-right {
					align-self: flex-end;
					bottom: 2.5rem;
					justify-content: flex-end;
				}
				&.bottom-center {
					align-self: flex-end;
					bottom: 2.5rem;
					justify-content: center;
				}
				&.bottom-left {
					align-self: flex-end;
					bottom: 2.5rem;
					justify-content: flex-start;
					text-align: left;
				}
				&.top-right {
					align-self: flex-start;
					top: 5.5rem;
					justify-content: flex-end;
				}
				&.top-center {
					align-self: flex-start;
					top: 5.5rem;
					justify-content: center;
				}
				&.top-left {
					align-self: flex-start;
					top: 5.5rem;
					justify-content: flex-start;
				}
				h2 {
					color: white;
					font-size: 1.75rem;
					text-align: left;
				}
				p {
					color: white;
					padding: 0rem;
					text-align: left;
				}
				.content {
					display: grid;
					max-width: 75%;
					text-align: left;
					.button-wrapper {
						flex-direction: row;
						column-gap: 1rem;
						margin-top: 0.5rem;
						a {
							text-align: center;
							width: 200px;
							padding: 0.75rem 1rem;
						}
					}
				}
			}
		}
	}
}

@include responsive(large) {
	.slide {
		.content-wrapper {
			display: flex;
			position: absolute;
			width: 100%;
			padding: 1rem;
			top: 6.5rem;
			padding: $desktop-width-padding;
			.content {
				max-width: 50%;
			}
		}
	}
}
