@import "./variables.scss";

.highlight-section {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%; 
    background-size: cover;
    color: white;
    .section-heading {
        display: flex;
        flex-direction: column;
        border-bottom: 2px solid $factorycatred;
        padding: $mobile-width-padding;
        padding-top: 1rem;
        padding-bottom: 1rem;
        background: black;
        width: 100%;
        h1 {
            font-family: "titillium-web", sans-serif;
            span {
                color: $factorycatred;
                font-size: 1.5rem;
            }
        }
        h2 {
            font-family: "titillium-web", sans-serif;
            font-size: 1.1rem;
            font-weight: normal;
            color: rgba($color: white, $alpha: .75);
            margin-top: .125rem;
            &.title {
                font-weight: bold;
                font-family: "titillium-web", sans-serif;
                color: white;
                span {
                    color: $factorycatred;
                    font-size: 1rem;
                } 
            }
        }
        h3 {
            font-family: "titillium-web", sans-serif;
            font-size: 1.1rem;
            font-weight: normal;
            color: rgba($color: white, $alpha: .75);
            margin-top: .125rem;
        }
    }
    .section-info {
        display: flex;
        flex-wrap: wrap;
        padding-top: 2rem;
        padding-bottom: 2rem;
        justify-content: space-between;
        row-gap: .5rem;
        column-gap: 1rem;
        padding: $mobile-width-padding;
        width: 100%;
        .spec {
            display: flex;
            justify-content: space-between;
            padding: 1rem 0rem;
            width: calc(50% - .5rem);
            background: rgba($color: #000000, $alpha: .5);
            border-bottom: 1px solid rgba($color: #ffffff, $alpha: .35);
            text-align: initial;
            h3 {
                display: flex;
                align-items: center;
                font-size: .7rem;
                text-align: initial;
            span {
                text-align: initial;
                color: $factorycatred;
                width: max-content;
                margin-left: .5rem;
                font-size: .6rem;
            }
            }
            &:nth-child(4n-1), &:nth-child(4n) {
                h3 {

                }
            }
        }
    }
    
}

  @include responsive(small) {
    
  }

  @include responsive(medium) {
    .highlight-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        .section-heading {
            display: flex;
            flex-direction: column;
            padding: $notepad-width-padding;
            padding-top: 1rem;
            padding-bottom: 1rem;
            width: 100%;
            h1 {
                span {
                    color: $factorycatred;
                    font-size: 1.5rem;
                }
            }
            h2 {
                font-family: "titillium-web", sans-serif;
                font-size: 2rem;
                font-weight: normal;
                color: rgba($color: white, $alpha: .75);
                margin-top: .125rem;
                &.title {
                    font-family: "titillium-web", sans-serif;
                    color: white;
                    font-size: 1.5rem;
                    span {
                        color: $factorycatred;
                        font-size: 1rem;
                    } 
                }
            }
        }
        .section-info {
            display: flex;
            flex-wrap: wrap;
            padding-top: 2rem;
            padding-bottom: 2rem;
            justify-content: space-between;
            row-gap: .5rem;
            column-gap: 1rem;
            padding: $notepad-width-padding;
            width: 100%;
            .spec {
                display: flex;
                padding: 1rem 0rem;
                width: calc(50% - .5rem);
                background: rgba($color: #000000, $alpha: .5);
                border-bottom: 1px solid rgba($color: #ffffff, $alpha: .35);
                text-align: initial;
                h3 {
                    display: flex;
                    text-align: initial;
                    font-size: 1rem;
                span {
                    text-align: initial;
                    color: $factorycatred;
                    width: max-content;
                    margin-left: .5rem;
                    font-size: .8rem
                    }
                }
                &:nth-child(4n-1), &:nth-child(4n) {
                    h3 {
    
                    }
                }
            }
        }
    }
    
  }

  @include responsive(large) {
    .highlight-section {
        .section-heading {
            display: flex;
            flex-direction: column;
            padding: $desktop-width-padding;
            padding-top: 1rem;
            padding-bottom: 1rem;
            width: 100%;
            h1 {
                span {
                    color: $factorycatred;
                    font-size: 1.5rem;
                }
            }
            h2 {
                font-family: "titillium-web", sans-serif;
                font-size: 2rem;
                font-weight: normal;
                color: rgba($color: white, $alpha: .75);
                margin-top: .125rem;
                &.title {
                    font-family: "titillium-web", sans-serif;
                    color: white;
                    font-size: 2rem;
                    span {
                        color: $factorycatred;
                        font-size: 1rem;
                    } 
                }
            }
        }
        .section-info {
            padding: $desktop-width-padding;
            .spec {
                h3 {
                    font-size: 1.25rem;
                span {
                    font-size: 1rem
                    }
                }
            }
        }
    }
  }