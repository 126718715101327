@import "./variables.scss";

.why-page {
	display: grid;
	padding-top: 4rem;
	grid-template-columns: 1fr;
	align-items: flex-start;
	align-content: flex-start;
	background: rgba($color: #ffffff, $alpha: 1);
}
.why-section {
	display: grid;
	grid-template-columns: 1fr;
	padding: $mobile-width-padding;
	padding-top: 1rem;
	padding-bottom: 1rem;
	column-gap: 1rem;
	direction: rtl;
	text-align: center;
	.why-section-image {
		img {
			width: 100%;
			height: auto;
		}
	}
	&:nth-child(n + 2) {
		background: rgba($color: #ffffff, $alpha: 1);
		direction: ltr;
	}
}
.no-padded-page-header {
	padding: $mobile-width-padding;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

@include responsive(small) {
	.why-page {
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: flex-start;
		align-content: flex-start;
	}
	.no-padded-page-header {
		grid-column: 1 / span 2;
	}
	.why-section {
		display: grid;
		grid-template-columns: 1fr;
		text-align: left;
	}
}

@include responsive(medium) {
	.why-page {
		grid-template-columns: 1fr 1fr 1fr;
	}
	.why-section {
		padding: $notepad-width-padding;
		padding-top: 2rem;
		padding-bottom: 2rem;
		column-gap: 2rem;
		direction: rtl;
	}
	.no-padded-page-header {
		grid-column: 1 / span 3;
		padding: $notepad-width-padding;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}

@include responsive(large) {
	.why-section {
		padding: $desktop-width-padding;
		padding-top: 4rem;
		padding-bottom: 4rem;
		column-gap: 4rem;
	}
	.no-padded-page-header {
		padding: $desktop-width-padding;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}
