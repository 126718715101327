@import './variables.scss';

.full-line-brochure-btn {
    display: flex;
    height: 2.5rem;
    padding: 1rem 1.5rem;
    align-items: center;
    align-content: center;
    text-decoration: none;
    background: $factorycatred;
    font-size: .9rem;
    border-radius: .25rem;
    width: max-content;
    color: rgb(241, 241, 241);
    font-weight: bold;
    text-transform: uppercase;
    margin: 0rem 0rem 1rem 0rem;
    box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: .25);
    transition: ease-in-out 200ms;
    &:hover {
        box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: .5);
        color: rgb(255, 255, 255);

    }
}


.downloads {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    width: 100%;
    row-gap: 2rem;
    padding-bottom: 2rem;
    .download {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        height: auto;
        border: 1px solid rgb(47, 47, 47);
        h2 {
            grid-column: 1 / span 3;
            margin-bottom: 1rem;
            background: black;
            color: white;
            padding: .5rem .5rem .5rem .5rem;
            span {
                color: $factorycatred;
                font-size: 1rem;
            }
        }
        .download-image-wrapper {
            display: flex;
            align-content: center;
            align-items: center;
            a {
                display: flex;
                justify-content: center;
                justify-items: center;
                align-content: center;
                align-items: center;
                width: 100%;
                img {
                    width: max-content;
                    object-fit: contain;
                    height: 170px;
                }
            }

        }
        .description-wrapper {
            display: flex;
            flex-direction: column;
            padding: 1rem;
            ul {
                display: grid;
                height: 100%;
                list-style-type: none;
                row-gap: .25rem;
                align-items: flex-start;
                align-content: flex-start;
                li {
                    display: flex;
                    background: rgb(43, 43, 43);
                    height: 1.75rem;
                    color: white;
                    text-transform: uppercase;
                    font-size: .7rem;
                    border-radius: .125rem;
                    transition: ease-in-out 200ms;
                    box-shadow: 0px 2px 2px rgba($color: #000000, $alpha: .25);
                    box-shadow: inset 4px 4px 8px rgba($color: #ffffff, $alpha: .125);
                    border-bottom: 2px solid rgba($color: #000000, $alpha: 0);
                    &:hover {
                        background: black;
                        padding-left: 1rem;
                        border-bottom: 2px solid $factorycatred;
                        button {
                            font-weight: bold;
                        }
                    }

                    button {
                        display: flex;
                        background: transparent;
                        border: none;
                        padding: .5rem;
                        height: 100%;
                        width: 100%;
                        align-items: center;
                        text-decoration: none;
                        color: white;
                    }
                }
            }
        }
    }
}

@include responsive(small) {
    .downloads {
        display: flex;
        .download {
            .download-image-wrapper {
                a {
                    img {
                        height: 250px;
                    }
                }

            }
            .description-wrapper {
                ul {
                    li {
                        height: 2.25rem;
                    }
                }
            }
        }
    }
}

@include responsive(medium) {
    .downloads {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-evenly;
        .download {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 500px;
            column-gap: 1rem;
            .download-image-wrapper {

            }
            .description-wrapper {
                ul {
                    li {
                        height: 2.25rem;
                    }
                }
            }
        }
    } 
}

@include responsive(large) {
    .downloads {
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
        width: 100%;
        .download {
            column-gap: 1rem;
            width: 600px;
            .download-image-wrapper {


            }
            .description-wrapper {
                ul {
                    li {
                        height: 2.25rem;
                    }
                }
            }
        }
    } 
}