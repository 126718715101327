@import "./variables.scss";

.section-one {
    display: grid;
    width: 100%;
    // background-color: #000000;
    // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='12' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%239C92AC' fill-opacity='0.2' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    padding: $mobile-width-padding;
    padding-top: 1rem;
    padding-bottom: 1rem;
    column-gap: 1rem;
    row-gap: 1rem;
    .section-one-card {
        display: grid;
        height: 220px;
        width: 100%;
        border: 2px solid white;
        align-items: center;
        justify-items: center;
        transition: ease-in-out 200ms;
        background: rgba($color: #000000, $alpha: .5);
        box-shadow: 0px 16px rgba($color: #000000, $alpha: 1.0);
        h5 {
            font-size: 2rem;
            text-align: center;
            color: white;
            transition: ease-in-out 200ms;
            text-transform: uppercase;
            transition: ease-in-out 200ms;
        }
        &:hover {
            cursor: pointer;
            background: rgba($color: white, $alpha: .85);
            h5 {
                color: black;
                font-size: 2.25rem;
            }
        }
    }
}

@include responsive(small) {
    
}

@include responsive(medium) {
    .section-one {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: $notepad-width-padding;
        padding-top: 1rem;
        padding-bottom: 1rem;
        .section-one-card {
            width: calc(50% - .5rem);
            h5 {
                font-size: 2rem;
            }
        }
    }
}

@include responsive(large) {
    .section-one {
        padding: $desktop-width-padding;
        padding-top: 2rem;
        padding-bottom: 2rem;
        .section-one-card {
            width: calc(25% - 1rem);
        }
    }
}