@import "./variables.scss";

.machine-main-page {
	display: grid;
	font-family: "Roboto", sans-serif;
	padding-top: 4rem;
	row-gap: 0.5rem;
	background: black;
	grid-template-areas:
		"title-area"
		"bread-crumbs";
	.title-area {
		display: grid;
		grid-area: title-area;
		row-gap: 0.25rem;
		padding: 0.5rem;
		width: 100%;
		h1 {
			display: grid;
			align-content: flex-start;
			font-weight: normal;
			font-size: 0.7rem;
			color: #aaa;
			width: max-content;
			text-transform: uppercase;
		}
		.title {
			display: flex;
			align-items: flex-end;
			column-gap: 0.25rem;
			h2 {
				display: flex;
				align-content: flex-end;
				justify-content: flex-end;
				font-weight: bold;
				font-family: "titillium-web", sans-serif;
				color: #ccc;
				font-size: 2rem;
				margin-block-start: 0rem;
				line-height: 1.75rem;
			}
			.version {
				font-size: 0.85rem;
				font-weight: normal;
				color: $factorycatred;
			}
		}
	}
	.main-specs {
		display: grid;
		align-content: flex-start;
		justify-items: center;
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 0.25rem;
		text-align: left;
		font-size: 0.9rem;
		column-gap: 0.5rem;
		padding: 0.5rem0rem;
		.spec {
			display: grid;
			grid-template-columns: 1fr;
			column-gap: 1rem;
			align-content: flex-start;
			color: rgb(0, 0, 0);
			background: silver;
			width: 100%;
			padding: 0.5rem;
			.spec-title {
				display: grid;
				text-align: center;
				justify-content: center;
				align-content: flex-start;
				font-weight: bold;
				text-transform: uppercase;
				color: #333;
			}
			span {
				text-align: center;
			}
		}
	}
	.bread-crumbs {
		display: flex;
		column-gap: 0.5rem;
		grid-area: bread-crumbs;
		justify-content: flex-start;
		align-items: center;
		font-size: 0.95rem;
		padding: 0.5rem;
		color: white;
		a {
			color: #777;
			font-weight: bold;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.image-area {
		display: grid;
		justify-content: center;
		justify-items: center;
		width: 100%;
		padding: 1rem 0rem;
		.main-image {
			width: 75%;
			height: auto;
		}
	}
}
