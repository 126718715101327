@import "./variables.scss";

.platforms {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 2rem;
	padding-bottom: 2rem;
	width: 100%;
	a {
		text-decoration: none;
		color: inherit;
	}
	.platform {
		display: grid;
		grid-template-rows: auto 1fr auto;
		max-width: 100%;
		padding: 1rem;
		border: 1px solid rgba($color: #000000, $alpha: 0.5);
		height: 100%;
		.description-wrapper {
			display: grid;
			height: auto;
			align-content: flex-start;
			h2 {
				display: grid;
				margin: 0rem;
				padding: 0rem;
				grid-template-columns: auto 1fr;
				align-content: flex-start;
				align-self: flex-start;
				align-items: flex-start;
				height: 100%;
				span {
					font-size: 1.5rem;
					color: $factorycatred;
					margin-left: 0.5rem;
				}
			}
			p {
				display: grid;
				align-self: flex-start;
				font-size: 0.85rem;
				color: rgba($color: #000000, $alpha: 0.65);
				line-height: 1.25rem;
			}
		}

		.platform-image-wrapper {
			display: grid;
			align-content: flex-start;
			align-items: flex-start;
			align-self: flex-start;
			height: 210px;
			overflow: hidden;
			transition: ease-in-out 200ms;
			img {
				display: grid;
				justify-self: center;
				height: 200px;
				width: 100%;
				object-fit: contain;
			}
		}
		&:hover {
			button {
				background: $factorycatred;
			}
			.platform-image-wrapper {
				transform: scale(1.05);
			}
		}
	}
	button {
		display: grid;
		background: rgba($color: $factorycatred, $alpha: 0.75);
		width: 100%;
		border: none;
		padding: 1rem 0rem;
		color: white;
		font-weight: bold;
		margin-top: 1rem;
		transition: ease-in-out 200ms;
		height: max-content;
		cursor: pointer;
		align-self: flex-end;
		align-content: flex-end;
		align-items: flex-end;
	}
}
.all-platforms {
	display: grid;
	margin-top: 0.5rem;
	row-gap: 2rem;
	font-size: 0.9rem;
	.two-columns {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		justify-items: center;
		width: 100%;
		grid-template-columns: 1fr;
		grid-gap: 1rem;
		margin-top: 1rem;
		a {
			text-decoration: none;
			color: initial;
		}
		.platform-card {
			display: grid;
			grid-template-rows: auto 1fr auto;
			height: 100%;
			.platform-image-container {
				display: grid;
				width: 100%;
				height: max-content;
				justify-content: center;
				align-content: flex-end;
				img {
					display: flex;
					width: 100%;
					height: 300px;
					object-fit: contain;
				}
			}
			.platform-content-container {
				display: grid;
				align-content: flex-start;
				align-items: flex-start;
				height: max-content;
			}
			.platform-button-container {
				display: grid;
				height: 100%;
				align-self: flex-end;
				align-items: flex-end;
				align-content: flex-end;
				button {
					display: flex;
					margin-top: 1rem;
					background: black;
					text-decoration: none;
					padding: 1rem;
					height: max-content;
					color: white;
					svg {
						margin-right: 0.75rem;
					}
					&:hover {
						color: $factorycatred;
					}
				}
			}

			a {
				display: flex;
				align-self: flex-end;
				margin-top: 1rem;
				background: black;
				text-decoration: none;
				padding: 1rem;
				height: max-content;
				color: white;
				svg {
					margin-right: 0.75rem;
				}
				&:hover {
					color: $factorycatred;
				}
			}
			p {
				font-size: 0.8rem;
			}
		}
	}
}

@include responsive(small) {
	.platforms {
		flex-wrap: wrap;
		justify-content: space-evenly;
		.platform {
			display: grid;
			grid-template-rows: auto 1fr auto;
			flex-direction: column;
			width: 325px;
			align-content: flex-start;
			.platform-image-wrapper {
				display: flex;
				justify-items: center;
				width: 100%;
				height: auto;
				object-fit: contain;
				img {
					width: 100%;
					height: 300px;
					object-fit: contain;
				}
			}
		}
	}
}
@include responsive(medium) {
	.all-platforms {
		display: grid;
		h2 {
			font-size: 2rem;
		}
		.two-columns {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
			grid-gap: 2rem;
			.platform-card {
				width: 300px;
				.platform-image-container {
					img {
						width: 100%;
						height: 280px;
					}
				}
				.content-container {
					display: flex;
					align-content: flex-start;
					align-items: flex-start;
					flex-direction: column;
					p {
						font-size: 0.9rem;
					}
				}
			}
		}
	}
}
@include responsive(large) {
	.all-platforms {
		display: grid;
		.two-columns {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			.platform-card {
				.platform-image-container {
					img {
					}
				}
				.platform-content-container {
				}
				.platform-button-container {
				}
			}
		}
	}
}
