@import "./variables.scss";

.parts-explorer__page {
    display: grid;
    align-items: flex-start;
    align-content: flex-start;
    margin: auto;
    padding-top: 3.5rem;
}
.load-more-btn {
    padding: .5rem 1rem;
    border: none;
    box-shadow: 0 0 5px rgba($color: #000000, $alpha: .5);
    border-radius: 5px;
    background-color: $factorycatred;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}
.hidden {
    display: none;
}
.parts-explorer-results {
    input {
        width: 100%;
        padding: .5rem;
        margin-bottom: 1rem;
        border: 1px solid rgba($color: #000000, $alpha: .5);
        border-radius: 5px;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
            display: flex;
            border-bottom: 1px solid rgba($color: #000000, $alpha: .5);
            a {
                display: block;
                padding: .25rem;
                width: 100%;
                height: 100%;
                color: black;
                text-decoration: none;
                font-weight: bold;

                &:hover {
                    background-color: rgba($color: #000000, $alpha: .1);
                }
            }

            &:last-child {
                border-bottom: none;
            }

            .part-description {
                font-size: .8rem;
                color: rgba($color: #000000, $alpha: .75);
            }
        }

    }
    .part-link {
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        font-weight: bold;
    }
}

@include responsive(medium) {
    .parts-explorer__page {
        padding-top: 3.5rem;
    }
    .parts-explorer-results {
        ul {
            li {
                grid-template-columns: 1fr 3fr;
                .part-description {
                    font-size: 1rem;
                }
            }
        }
    }
}