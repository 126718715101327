@import "./variables.scss";

.contact-us {
    display: grid;
    grid-template-columns: 1fr;
    border-top: 4px solid $factorycatred;
    width: 100%;
    .left {
        display: grid;
        width: 100%;
        align-items: center;
        align-content: center;
        background: black;
        padding: 1rem .5rem 1rem .5rem;
        color: white;
        iframe {
            width: 100%;
            height: 300px;
        }
        ul {
            list-style-type: none;
            margin-top: 1rem;
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid rgba($color: #ffffff, $alpha: .5);
            li {
                div {
                    font-size: .9rem;
                    color: rgba($color: #ffffff, $alpha: .8);
                }
                a {
                    font-weight: normal;
                }
            }
        }
        h1 {
            height: max-content;
            margin: 0rem;
            align-self: flex-start;
            margin-bottom: 1rem;
        }
        h2 {
            margin: 0rem;
            align-self: flex-start;
            margin-bottom: 0rem;
        }
        p {
            color: rgba($color: #ffffff, $alpha: .8);
            line-height: 1.5rem;
            width: 100%;
        }
    }
    .right {
        display: grid;
        padding: 1rem .5rem 1rem .5rem;
        color: white;
        width: 100%;
    }
    .contact-form {
        display: grid;
        row-gap: 1rem;
        input {
            padding: .75rem;
        }
        textarea {
            height: 200px;
            padding: 1rem;
        }
        button {
            padding: 1rem;
            background: black;
            border: none;
            cursor: pointer;
            color: white;
            font-weight: bold;
        }
    }
}
.contact-form {
    display: grid;
    row-gap: 1rem;
    input {
        padding: .75rem;
    }
    textarea {
        height: 200px;
        padding: 1rem;
    }
    button {
        padding: 1rem;
        background: black;
        border: none;
        cursor: pointer;
        color: white;
        font-weight: bold;
    }
}   
@include responsive(small) {

}

@include responsive(medium) {
    .contact-us {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .left {
            padding: 1rem 2rem 1rem 2rem;
        }
        .right {
            padding: 1rem 2rem 1rem 2rem;
        }
    }
    
}

@include responsive(large) {
    .contact-us {
        display: grid;
        grid-template-columns: 2fr 3fr;
        .contact-form {
            justify-self: center;
            width: 600px;
        }
        .left {
            padding: 2rem 4rem 2rem 4rem;
        }
        .right {
            padding: 2rem 4rem 2rem 4rem;
        }
    }
}