@import "./variables.scss";

.support-us {
    display: grid;
    grid-template-columns: 1fr;
    border-top: 4px solid $factorycatred;
    width: 100%;
    .left {
        display: grid;
        width: 100%;
        align-items: center;
        align-content: center;
        background: black;
        padding: 1rem .5rem 1rem .5rem;
        color: white;
        ul {
            list-style-type: none;
            margin-top: 1rem;
            li {
                margin: .5rem 0rem;
                a {
                    display: flex;
                    height: 100%;                
                    padding: .75rem;
                    width: 100%;
                    text-decoration: none;
                    font-size: .85rem;
                    &:hover {
                        cursor: pointer;
                        background: #333;
                        border-radius: .25rem;
                    }
                }
            }
        }
        h1 {
            height: max-content;
            margin: 0rem;
            align-self: flex-start;
            margin-bottom: 1rem;
        }
        p {
            color: rgba($color: #ffffff, $alpha: .8);
            line-height: 1.5rem;
            width: 100%;
        }
    }
    .right {
        display: grid;
        align-items: flex-start;
        align-content: flex-start;
        padding: 1rem .5rem 1rem .5rem;
        color: black;
        width: 100%;
    }
    .contact-form {
        display: grid;
        row-gap: 1rem;
        input {
            padding: .75rem;
        }
        textarea {
            height: 200px;
            padding: 1rem;
        }
        button {
            padding: 1rem;
            background: black;
            border: none;
            cursor: pointer;
            color: white;
            font-weight: bold;
        }
    }
}   
@include responsive(small) {

}

@include responsive(medium) {
    .support-us {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .left {
            padding: 1rem 2rem 1rem 2rem;
        }
        .right {
            padding: 1rem 2rem 1rem 2rem;
        }
    }
    
}

@include responsive(large) {
    .support-us {
        display: grid;
        grid-template-columns: 1fr 2fr;
        .contact-form {
            justify-self: center;
            width: 600px;
        }
        .left {
            padding: 2rem 4rem 2rem 4rem;
        }
        .right {
            padding: 2rem 4rem 2rem 4rem;
        }
    }
}