@import "./variables.scss";
.about-page {
	display: grid;
	margin: auto;
	max-width: 1200px;
	grid-template-columns: 1fr;
	row-gap: 1rem;
	p {
		font-size: 1rem;
		line-height: 1.5rem;
		padding: 0rem;
	}

	.about-text {
		display: grid;
		row-gap: 1rem;
		position: relative;
		h1 {
			font-size: 2rem;
			padding: 0rem 0rem 0rem 0.5rem;
			margin: 0rem 0rem 0rem 0rem;
		}
	}
	.about-image {
		float: left;
		margin-right: 20px;
		width: 100%; /* Adjust this width based on your needs */
		height: auto;
	}
	.about-h2 {
		margin: 0px 0px 0px 0px;
		margin-bottom: 0px;
	}
}

@include responsive(small) {
	.about-page {
		display: block;
		.about-text {
			display: block;
			position: relative;
			p {
				padding: 0.5rem 0rem;
				&:nth-child(1) {
					padding-top: 0rem;
				}
			}
		}

		.about-image {
			float: left;
			margin-right: 20px;
			margin-bottom: 10px;
			width: 40%; /* Adjust this width based on your needs */
		}
		.about-h2 {
			margin-top: 0;
		}
	}
}

@include responsive(medium) {
}
