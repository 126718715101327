@import "./variables.scss";

.applications-page {
	.applications-thumb-wrapper {
		display: flex;
		margin-top: 2rem;
		flex-direction: column;
		row-gap: 1rem;
		.application-thumb {
			display: flex;
			flex-direction: column;
			padding: 1rem;
			width: 100%;
			column-gap: 1rem;
			box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
			text-decoration: none;
			color: black;
			h2 {
				text-align: right;
			}
			p {
				padding-top: 0.25rem;
				font-size: 0.85rem;
			}
			a {
				display: flex;
				width: max-content;
				text-decoration: none;
				background: black;
				color: white;
				padding: 0.5rem 1rem;
				margin-top: 0.5rem;
			}

			.application-thumb-image {
				display: flex;
				width: 100%;
				height: 100%;
				object-fit: cover;
				img {
					height: auto;
					width: 100%;
				}
			}
		}
	}
}

@include responsive(small) {
	.applications-page {
		.applications-thumb-wrapper {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-around;
			justify-items: center;
			row-gap: 1rem;
			.application-thumb {
				display: flex;
				flex-direction: column;
				width: 300px;
				padding: 1rem;
				column-gap: 0.5rem;
				box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
				cursor: pointer;
				transition: all 0.25s ease-in-out;
				img {
					transition: all 0.25s ease-in-out;
					opacity: 0.75;
				}
				&:hover {
					img {
						opacity: 1;
					}
				}
				a {
					display: flex;
					width: max-content;
					text-decoration: none;
					background: black;
					color: white;
					padding: 0.5rem 1rem;
					margin-top: 0.5rem;
				}

				.application-thumb-image {
					display: flex;
					width: 100%;
					height: 100%;
					object-fit: cover;
					img {
						object-fit: cover;
						height: auto;
						width: 100%;
					}
				}
			}
		}
	}
}
