@import './variables.scss';

.controller-options {
    display: flex;
    flex-direction: column;
    background: white;
    .section-title {
        display: grid;
        color: white;
        font-size: 2rem;
        font-weight: bold;
        padding-bottom: 1rem;
        text-align: center;
        color: black;
    }
    .options-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        .option {
            display: flex;
            flex-direction: column;
            .option-image-wrapper {
                display: flex;
                overflow: hidden;
                margin-bottom: 1rem;
                border: solid 2px black;
            }
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}

@include responsive(small) {
    .controller-options {
        display: flex;
        flex-direction: column;
        .options-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            flex-direction: column;
            row-gap: 2rem;
            column-gap: 2rem;
        }
    }
}

@include responsive(medium) {
    .controller-options {
        display: flex;
        flex-direction: column;
        .options-wrapper {
            display: flex;
            flex-wrap: wrap;
            .option {
                display: grid;
                grid-template-columns: 1fr 2fr;
                column-gap: 2rem;
                .option-image-wrapper {
                    display: grid;
                    align-items: flex-start;
                    align-content: flex-start;
                    overflow: hidden;
                    width: 100%;
                    height: max-content;
                    img {
                        height: max-content;
                        object-fit: contain;
                        object-position: 0px 0px;
                    }
                }
            }
        }
    }
}

@include responsive(large) {
    .controller-options {
        display: flex;
        flex-direction: column;
        padding: 2rem 0rem;
        .options-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            width: 100%;
            .option {
                display: grid;
                align-content: flex-start;
                grid-template-columns: 1fr;
                column-gap: 1rem;
                max-width: 500px;
                .option-image-wrapper {
                    display: grid;
                    align-items: flex-start;
                    align-content: flex-start;
                    overflow: hidden;
                    width: 100%;
                    img {
                    }
                }
            }
        }
    }
}



