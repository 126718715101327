@import "./variables.scss";
.scrubber-types-page {
	width: 100%;
	height: max-content;
	background-color: #272727;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23000000' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
	h1 {
		color: white;
		text-align: center;
		padding: 1.5rem 0rem;
	}
	border-top: 2px solid $factorycatred;
}

.scrubber-types-wrapper {
	display: flex;
	flex-wrap: wrap;

	a {
		text-decoration: none;
	}
	.scrubber-type {
		display: flex;
		position: relative;
		flex-direction: column;
		cursor: pointer;
		width: 100%;
		padding: 2rem 0rem;
		transition: all ease-in-out 500ms;
		button {
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			background: rgba($color: $factorycatred, $alpha: 0.5);
			border: 1px solid white;
			color: white;
			padding: 0.5rem 2rem;
			opacity: 0;
			position: absolute;
			cursor: pointer;
			transition: all ease-in-out 200ms;
		}
		&:hover {
			animation: growingShadow 200ms forwards; /* Stop the animation on hover */
			img {
				opacity: 1;
				transform: scale(1.1);
			}
			p,
			h1 {
				color: $factorycatred;
				// border-bottom: 4px solid white;
			}
			button {
				opacity: 1;
				background: $factorycatred;
			}
		}

		img {
			width: 65%;
			align-self: center;
			height: auto;
			padding: 0.5rem;
			object-fit: contain;
			opacity: 0.85;
			transition: all ease-in-out 200ms;
		}
		h2 {
			display: flex;
			justify-content: center;
			color: rgb(218, 218, 218);
			font-size: 1.25rem;
			width: 100%;
			transition: all ease-in-out 200ms;
			padding: 0.5rem 0rem;
			text-align: center;
			border-bottom: 4px solid rgba($color: #000000, $alpha: 0);
		}
		p {
			color: black;
			transition: all ease-in-out 200ms;
		}
	}
}
@include responsive(small) {
	.scrubber-types-wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		.scrubber-type {
			display: flex;
			flex-direction: column;
			height: 100%;
			img {
				height: 200px;
				width: auto;
			}
		}
	}
}
@include responsive(medium) {
	.scrubber-types-wrapper {
		grid-template-columns: 1fr 1fr;
		.scrubber-type {
			display: flex;
			width: 100%;
			img {
				height: 200px;
			}
		}
	}
}
@include responsive(large) {
	.scrubber-types-wrapper {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		.scrubber-type {
			h2 {
				font-size: 1.5rem;
			}
		}
	}
}

@keyframes growingShadow {
	0% {
		background-image: radial-gradient(
			circle at 50% 50%,
			rgba(0, 0, 0, 0),
			rgba(0, 0, 0, 0)
		);
		background-size: 0% 0%;
		background-repeat: no-repeat;
		background-position: center;
	}
	100% {
		background-image: radial-gradient(
			circle at 50% 50%,
			rgba(0, 0, 0, 0),
			rgba(0, 0, 0, 0.25)
		);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;
	}
}
