@import "./variables.scss";

.alert-wrapper {
	display: grid;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 4;
	background: rgba(0, 0, 0, 0.5);
}
.alert-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: black;
	height: 2rem;
	width: 100%;
	color: white;
	padding: 0.5rem;
	svg {
		cursor: pointer;
		&:hover {
			color: $primary-color;
		}
	}
}
.alert-content-wrapper {
	display: grid;
	align-content: flex-start;
	position: relative;
	top: 35%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 500px;
	height: max-content;
	background: white;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
	z-index: 5;
}
.alert-message {
	padding: 0.5rem;
	width: 100%;
	ul {
		color: black;
	}
	li {
		display: flex;
		font-size: 0.8rem;
	}
	.label {
		display: flex;
		font-weight: bold;
		font-size: 0.9rem;
	}

	a {
		color: black;
	}
	p {
		color: #000;
		font-size: 0.9rem;
	}
}

@include responsive(medium) {
	.alert-content-wrapper {
	}
}
