@import "./variables.scss";

.rental-page {
	display: grid;
	margin-top: 3rem;
	grid-template-columns: 1fr;
	p {
		line-height: 2rem;
		font-size: 1.25rem;
		color: #555;
		a {
			font-weight: 600;
			color: $factorycatred;
			text-decoration: none;
		}
	}
	.content-column {
		display: grid;
		grid-template-columns: 1fr;
		row-gap: 1rem;
		.rental-page-header {
			display: grid;
			img {
				width: 100%;
				height: 200px;
				object-fit: cover;
			}
		}
		.content-head {
			display: grid;
			padding: 0rem 0.5rem;
			grid-column: 1 span 0;
		}
		.content-body {
			display: grid;
			grid-template-columns: 1fr;
			row-gap: 1rem;
			padding: 0rem 0.5rem;
			align-content: flex-start;
			.content-body-head {
				display: grid;
				align-content: flex-start;
				grid-column: 1 / span 0;
				h2 {
					color: black;
					border-left: 4px solid #333;
					padding-left: 0.5rem;
				}
			}
			.content-body-details {
				display: grid;
				align-content: flex-start;
				h3 {
					color: #333;
				}
				&.benefit {
					background: white;
					width: 100%;
					// box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.25);
				}
			}
		}
		.divider {
			grid-column: 1;
			border-top: 1px solid #ccc;
			margin: 1rem 0;
		}
	}
}
.rental-form {
	display: grid;
	align-items: flex-start;
	align-content: flex-start;
	height: 100%;
	width: 100%;
	background: white;
	row-gap: 1rem;
	.form-header {
		display: grid;
		height: max-content;
		padding-bottom: 0.75rem;
		background: black;
		padding: 1rem 0.5rem 0rem 0.5rem;
		text-align: center;
		h2 {
			color: white;
			font-size: 1.5rem;
		}
		p {
			line-height: 1.25rem;
		}
	}
	img {
		display: grid;
		justify-self: center;
		width: 300px;
		height: 100%;
		object-fit: cover;
		padding-bottom: 1rem;
	}
	.form-inputs {
		display: grid;
		padding: 0.5rem;
		div {
			display: grid;
			row-gap: 0.25rem;
		}
	}
	h3 {
		color: black;
	}
	p {
		color: #999;
		font-size: 1rem;
		padding: 0rem 0rem;
	}
	label {
		color: black;
	}
	input,
	select {
		padding: 0.5rem;
		height: 2rem;
	}
	button {
		padding: 0.75rem;
		background-color: $factorycatred;
		color: white;
		margin-top: 0.25rem;
		border: none;
		border-radius: 4px;
		cursor: pointer;
	}
}
.rental-form-side-bar {
	display: none;
}
.fixed-rental-form-wrapper {
	display: grid;
	background-color: white;
	position: fixed;
	color: white;
	z-index: 98;
	bottom: 0;
	right: 0;
	transition: all 0.3s ease-in-out;
	width: 100%;
	height: 100%;
	transform: translateY(100%);
	&.open {
		transform: translateY(0);
	}
}
.fixed-rental-bar {
	display: grid;
	position: fixed;
	justify-content: center;
	justify-items: center;
	align-content: center;
	background-color: black;
	color: white;
	z-index: 100;
	bottom: 0rem;
	left: 50%;
	transform: translate(-50%, 0%);
	width: 100%;
	padding: 1rem;
	border-top: 2px solid grey;
	box-shadow: 10px 15px 1rem 0px rgba(0, 0, 0, 0.25);
	text-align: center;
	transition: all ease-in-out 0.3s;
	align-items: center;
	align-content: center;
	row-gap: 1rem;
	img {
		width: 220px;
		height: 100%;
		object-fit: cover;
	}
	button {
		border: none;
		background: transparent;
		font-size: 1.25rem;
		color: white;
		border-radius: 4px;
		font-weight: bold;
		border-radius: 4rem;
		padding: 0rem 1rem;
		border: 1px solid white;
		padding: 0.5rem 1rem;
	}
}

@include responsive("small") {
	.rental-page {
		display: grid;
		.content-column {
			display: grid;
			.rental-page-header {
				img {
					width: 100%;
					height: 400px;
					object-fit: cover;
				}
			}
			.divider {
				grid-column: 1;
				border-top: 1px solid #ccc;
				margin: 1rem 0;
			}
			.content-head {
				display: grid;
			}
		}
	}
	.fixed-rental-form-wrapper {
		background: rgba(0, 0, 0, 0.25);
	}
	.rental-form {
		margin: auto;
		max-width: 400px;
		height: max-content;
		box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.25);
	}
}

@include responsive("medium") {
	.fixed-rental-form-wrapper {
		display: none;
	}
	.fixed-rental-bar {
		display: none;
	}
	.rental-page {
		grid-template-columns: 1fr 500px;
		column-gap: 4rem;
		.content-column {
			row-gap: 3rem;
			.rental-page-header {
				padding: 2rem 0rem 0rem 4rem;
			}
			.content-head {
				margin-left: 4rem;
				padding: 0rem;
				h1 {
					padding-bottom: 0.5rem;
					border-bottom: 3px solid $factorycatred;
				}
			}
			.content-body {
				display: grid;
				grid-template-columns: 1fr;
				row-gap: 3rem;
				column-gap: 3rem;
				padding: 0rem 0rem 4rem 4rem;
				align-content: flex-start;
				.content-body-details {
					display: grid;
					align-content: flex-start;
				}
			}
			.divider {
				grid-column: 1;
				border-top: 1px solid #ccc;
				margin: 1rem 0;
			}
		}
		.rental-form-side-bar {
			display: grid;
			position: relative;
			width: 100%;
			height: max-content;
			padding: 0rem 4rem 4rem 0rem;
			.rental-form {
				position: fixed;
				top: 5rem;
				height: min-content;
				background: black;
				padding-bottom: 1rem;
				box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.25);
				.form-inputs {
					row-gap: 0.25rem;
					label {
						color: white;
					}
					padding: 0rem 1rem;
				}
				.form-header {
					padding: 1rem 2rem 0rem 2rem;
				}
			}
		}
	}
}

@include responsive("large") {
	.rental-page {
		align-content: flex-start;
		align-items: flex-start;
		.rental-page-header {
			grid-column: 1;
			height: min-content;
		}
		.content-column {
			grid-column: 1;
			align-items: flex-start;
			align-content: flex-start;
			.content-body-head {
				grid-column: 1 / span 2;
			}
			.content-body {
				grid-template-columns: 1fr 1fr;
				column-gap: 4rem;
				.content-body-details {
				}
			}
			.divider {
				grid-column: 1 / span 2;
				border-top: 1px solid #ccc;
				margin: 1rem 0;
			}
		}
		.rental-form-side-bar {
			grid-row: 1;
			grid-column: 2;
		}
	}
}
