@import "./variables";


.size-changer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 1rem 0rem;
    a {
        text-decoration: none;
    }
    .button-wrapper {
        display: flex;
        width: 100%;
        font-size: .7rem;
        justify-content: space-evenly;
        text-align: center;
        h5 {
            text-transform: uppercase;
        }
        .scrubber-name {
            font-weight: normal;
            color: rgb(243, 243, 243);
            text-transform: initial;
        }
        .button-div {
            display: grid;
            align-items: center;
            grid-template-columns: 3fr 1fr;
            background: rgb(39, 39, 39);
            color: white;
            width: 100px;
            cursor: pointer;
            transition: ease-in-out 200ms;
            h5 {
                transition: ease-in-out 200ms;
            }
            &.smaller {
                grid-template-columns: 1fr 3fr;
            }
            div {
                padding: .5rem;
            }
            .icon {
                display: flex;
                align-items: center;
                justify-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background: red;
                svg {
                    font-size: 1.5rem;
                    color: rgba($color: #000000, $alpha: .75);
                    transition: ease-in-out 200ms;
                }
            }
            &:hover {
                .scrubber-name {
                    color: $factorycatred;
                }
                h5 {
                    color: white;
                }
                .icon {
                    color: $factorycatred;
                    svg {
                        color: white;
                    }
                }
            }
        }
    }
}

@include responsive(small) {
    .size-changer {
        .button-wrapper {
            width: 65%;
            .button-div {
                display: grid;
                align-items: center;
                grid-template-columns: 3fr 1fr;
                background: rgb(39, 39, 39);
                color: white;
                width: 160px;
                cursor: pointer;
            }
        }
    }
}
@include responsive(medium) {
    .size-changer {
        justify-content: space-between;
        .button-wrapper {
            width: 75%;
        }
    }
}