@use "./variables.scss";

.serial-search-results {
	display: grid;
	margin: auto;
	max-width: 800px;
	font-size: 0.9rem;
	.serial-h1 {
		background: black;
		color: white;
		padding-left: 0px;
		border: none;
		padding: 1rem 0.75rem;
		margin: 0rem;
		font-size: 1.5rem;
	}
	.serial-search-results__column {
		display: grid;
		row-gap: 0.5rem;
		h2 {
			margin: 0rem;
			font-size: 1.25rem;
			padding: 0.25rem;
		}
	}
	.serial-search-results__info {
		display: grid;
		h3 {
			margin: 0rem;
			padding: 0.5rem 0.25rem 0.25rem 0.25rem;
			border-bottom: 1px solid black;
			margin-bottom: 0.5rem;
		}
	}
	.serial-search-results__details {
		display: grid;
	}
	.serial-search-results__table {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		column-gap: 1rem;
		.serial-search-results__table__row {
			display: grid;
			padding: 0rem 1rem;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: auto;
			row-gap: 1rem;
			column-gap: 1rem;
			padding: 0.25rem;
			&:nth-child(even) {
				background: #ddd;
			}
			.serial-search-results__table__row__title {
			}
			.serial-search-results__table__data {
				svg {
					margin-left: 0.5rem;
				}
			}
		}
	}
	.manuals-wrapper {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin-top: 1rem;
		.manual-link {
			display: grid;
			align-content: flex-start;
			row-gap: 0.5rem;
			justify-content: center;
			justify-items: center;
			text-decoration: none;
			color: black;
			max-width: 30%;
			text-align: center;
			svg {
				font-size: 3rem;
			}
		}
	}
}
