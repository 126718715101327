@import './variables.scss';

.videos-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: .5rem;
    column-gap: .5rem;
    .video {

    }
}

@include responsive(small) {
    .videos-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .video {
            width: 100%;
        }
    }
}

@include responsive(medium) {
    .videos-wrapper {

        grid-template-columns: 1fr 1fr 1fr 1fr;
        row-gap: .75rem;
        column-gap: .75rem;
        .video {
            width: 100%;
        }
    }
}

@include responsive(large) {
    .videos-wrapper {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        row-gap: 1rem;
        column-gap: 1rem;
        .video {
            width: 100%;
        }
    }
}