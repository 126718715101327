@import "./variables.scss";

.view-all-models-link {
	display: flex;
	align-items: center;
	justify-content: center;
	width: max-content;
	border: none;
	padding: 0.75rem 2rem;
	font-weight: bold;
	text-decoration: none;
	background: $factorycatred;
	font-size: 0.9rem;
	color: white;
	transition: ease-in-out 200ms;
	border-radius: 0.125rem;
	&:hover {
		background: rgb(255, 60, 60);
	}
}
.view-models-link {
	display: flex;
	width: max-content;
	border: none;
	padding: 0.75rem 1rem;
	font-weight: bold;
	text-decoration: none;
	background: $factorycatred;
	font-size: 0.9rem;
	color: white;
	transition: ease-in-out 200ms;
	border-radius: 0.125rem;
	&:hover {
		background: rgb(255, 60, 60);
	}
}

.cta-btn-1 {
	display: flex;
	align-items: center;
	justify-content: center;
	background: white;
	width: 100%;
	border: none;
	padding: 0.75rem 2rem;
	font-weight: bold;
	text-decoration: none;
	color: black;
	font-size: 0.9rem;
	&:hover {
		background: black;
		color: #ed2024;
	}
}

.request-demo-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	background: white;
	width: max-content;
	border: none;
	padding: 0.75rem 2rem;
	font-weight: bold;
	text-decoration: none;
	background: $factorycatred;
	color: white;
	font-size: 0.9rem;
	color: white;
	&:hover {
		background: black;
		color: white;
	}
}

.form-submit {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	border: none;
	padding: 0.75rem 2rem;
	font-weight: bold;
	text-decoration: none;
	color: white;
	background: black;
	font-size: 0.9rem;
	&:hover {
		background: black;
		color: #ed2024;
	}
}

.cta-button {
	display: flex;
	color: $paragraph2-color;
	align-content: center;
	align-items: center;
	padding: 0.5rem 1.5rem;
	border: 1px solid $paragraph2-color;
	background: none;
	font-weight: bold;
	transition: ease-in-out 200ms;
	&:hover {
		color: white;
		background: rgb(30, 30, 30);
		font-weight: normal;
	}
	&.black {
		color: $paragraph1-color;
		background-color: $paragraph5-color;
	}
	&.card {
		color: $paragraph6-color;
		border: 1px solid $paragraph6-color;
		width: max-content;
		border-radius: 0.5rem;
		font-weight: normal;
	}
	&.hero {
		color: $paragraph6-color;
		border: 1px solid $paragraph6-color;
		width: max-content;
		border-radius: 0.5rem;
		font-weight: normal;
	}
	&.heroTwo {
		color: $paragraph1-color;
		background: white;
		border: 1px solid $paragraph6-color;
		width: max-content;
		border-radius: 0.5rem;
		font-weight: normal;
		text-transform: uppercase;
		font-weight: bold;
	}
}
