@import "./variables.scss";

.request-demo-form {
	display: flex;
	flex-direction: column;
	padding: $mobile-padding;
}

.form-two-column {
	display: grid;
	grid-template-columns: 1fr;
}

.form-three-column {
	display: grid;
	grid-template-columns: 1fr;
}
.error {
	color: red;
	font-size: 0.75rem;
}
label {
	padding: 0rem;
	margin: 0rem;
	span {
		margin-left: 0.5rem;
		font-weight: lighter;
	}
}

.input-group {
	display: flex;
	flex-direction: column;
	padding: 0.5rem 0rem;
	p {
		&.error {
			font-weight: bold;
		}
	}

	label {
		font-size: 0.8rem;
		font-weight: 600;
		color: #555;
		margin-bottom: 0.25rem;
		&.error {
			color: rgb(252, 107, 59);
			font-weight: bold;
			font-size: 0.65rem;
			margin-left: 0.5rem;
		}
	}
	input,
	select {
		display: flex;
		width: 100%;
		height: 2.75rem;
		border-radius: 0.125rem;
		padding: 0.75rem;
		border: none;
		color: black;
		// box-shadow: inset -4px -2px 4px rgba($color: #000000, $alpha: .05);
		border: 1px solid rgba($color: #000000, $alpha: 0.125);
		&::placeholder {
			color: rgba(65, 65, 65, 0.5);
		}
	}
	textarea {
		border-radius: 0.25rem;
		padding: 0.75rem;
		border: none;
		background: white;
		color: black;
		height: 100px;
		resize: none;
		border: 1px solid rgba($color: #000000, $alpha: 0.125);
		&::placeholder {
			color: rgba(65, 65, 65, 0.5);
		}
	}
}

.form-section {
	padding-top: 1rem;
	&:first-child {
		padding-top: 0rem;
	}
}

.dev-button {
	height: 3rem;
	width: max-content;
	padding: 0.5rem 1rem;
	border-radius: 0.25rem;
	background: $primary-color;
}

@include responsive(small) {
}

@include responsive(medium) {
	.form-two-column {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 1rem;
	}
	.form-three-column {
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 1rem;
	}
}
