.machine-page {
	display: flex;
	flex-direction: column;
	.main-section-wrapper {
		display: flex;
		justify-content: space-between;
	}
	.image-thumb-changer-wrapper {
		display: grid;
		width: 100%;
	}
	.thumbs-image-wrapper {
		display: grid;
		grid-template-columns: auto 1fr;
		column-gap: 1rem;
		width: 100%;
		.thumbnails-wrapper {
			display: flex;
			flex-direction: column;
			align-content: space-between;
			justify-content: space-between;
			width: 50px;
			row-gap: 0.25rem;
			.thumb {
				width: 50px;
				height: 50px;
				border: 1px solid black;
				cursor: pointer;
				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
					padding: 0.25rem;
				}
			}
		}
		.image-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100%;
			width: 100%;
			img {
				height: 275px;
				width: auto;
				object-fit: contain;
			}
		}
	}
	.heading-wrapper {
		display: grid;
		align-items: center;
		height: max-content;
		&.desktop {
			display: none;
		}
		.title-wrapper {
			display: flex;
			width: 100%;
			justify-content: space-between;
			padding-bottom: 0.5rem;
			align-items: center;
			.title {
				font-size: 1rem;
				padding: 0rem;
				margin: 0rem;
				border: none;
				font-family: "titillium-web", sans-serif;
				font-weight: 700;
				span {
					font-family: "titillium-web", sans-serif;
					color: $factorycatred;
					font-size: 0.85rem;
				}
			}
			a {
				display: flex;
				align-items: center;
				align-content: center;
				text-align: center;
				color: black;
				text-decoration: none;
			}
			a {
				display: flex;
				width: max-content;
				align-items: center;
				background: $factorycatred;
				border: none;
				cursor: pointer;
				height: 2.5rem;
				padding: 0rem 1rem;
				color: white;
				font-weight: bold;
			}
		}
		.spec-wrapper {
			display: grid;
			grid-template-columns: 1fr 1fr;
			flex-wrap: wrap;
			width: 100%;
			justify-content: space-between;
			margin: 0rem 0rem 0.5rem 0rem;
			align-items: flex-start;
			column-gap: 0.25rem;
			row-gap: 0.1rem;
			.detail {
				display: grid;
				justify-content: center;
				justify-items: center;
				text-align: center;
				padding: 0.25rem;
				background: black;
				height: fit-content;
				width: 100%;
				h2 {
					margin: 0rem;
					font-size: 0.7rem;
					text-transform: uppercase;
					color: white;
					font-family: "titillium-web", sans-serif;
					font-weight: normal;
					letter-spacing: 0.05rem;
				}
				span {
					color: #d2d2d2;
					font-size: 0.65rem;
					font-family: "titillium-web", sans-serif;
					font-weight: bold;
				}
			}
		}
	}
	.details-section-wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		overflow: hidden;
		.description-wrapper {
			p {
				line-height: 1.25rem;
			}
		}
	}
	.deck-selector-wrapper {
		display: grid;
		column-gap: 1rem;
		align-items: flex-start;
		margin-top: 1rem;
		row-gap: 1rem;
		h3 {
			font-size: 0.9rem;
		}

		h4 {
			font-size: 0.8rem;
			font-weight: bold;
		}
		img {
			display: flex;
			justify-self: center;
			max-width: 175px;
			height: max-content;
			object-fit: contain;
		}
		.decks-wrapper {
			display: flex;
			flex-direction: column;
			row-gap: 1rem;
			button {
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid black;
				background-color: transparent;
				height: 2rem;
				width: 85px;
				font-weight: bold;
				&.selected {
					background: black;
					color: white;
				}
				&:hover {
					border: 1px solid $factorycatred;
				}
			}
			h3 {
				width: 100%;
				text-align: center;
				padding-bottom: 0.5rem;
			}
			.scrub-systems-wrapper {
				display: flex;
				width: 100%;
				justify-content: space-evenly;
				row-gap: 1rem;
				.scrub-system {
					display: flex;
				}
			}
			.scrubdeck-sizes-wrapper {
				display: grid;
				width: 100%;
				.deck-sizes {
					display: flex;
					justify-content: space-evenly;
				}
			}
			img {
				width: 50%;
				height: auto;
				object-fit: contain;
			}
		}
	}
	.manuals-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		padding: 1rem 0rem;
		row-gap: 0.5rem;
		a {
			text-decoration: none;
			color: black;
		}
		.manual {
			display: grid;
			align-items: flex-start;
			align-content: flex-start;
			justify-content: center;
			justify-items: center;
			cursor: pointer;
			height: max-content;
			padding: 0.5rem;
			width: 95px;
			height: 95px;
			&:hover {
				color: $factorycatred;
				.manual-image-wrapper {
					svg {
						fill: $factorycatred;
					}
				}
			}
			h4 {
				font-weight: normal;
				font-size: 0.9rem;
				text-align: center;
			}
			.manual-image-wrapper {
				display: grid;
				svg {
					height: 30px;
				}
			}
		}
	}
	.machine-demo-wrapper {
	}
}
.machine-demo-request {
	display: grid;
	grid-template-columns: 1fr;
	padding: 1rem;
	row-gap: 1rem;
	ul {
		list-style-type: none;
		li {
			display: flex;
			column-gap: 1rem;
			align-items: center;
			align-content: center;
			a {
				color: $factorycatred;
			}
			svg {
				color: $factorycatred;
			}
		}
	}
	.image-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		img {
			width: 100%;
			height: auto;
			object-fit: contain;
		}
	}
}

@include responsive(small) {
	.machine-page {
		.main-section-wrapper {
			align-items: flex-start;
			align-content: flex-start;
			.thumbs-image-wrapper {
				display: grid;
				grid-template-columns: auto 1fr;
				column-gap: 1rem;
				width: 100%;
				.thumbnails-wrapper {
					display: flex;
					flex-direction: column;
					align-content: space-between;
					justify-content: space-between;
					width: 75px;
					row-gap: 0.25rem;
					.thumb {
						width: 75px;
						height: 75px;
						border: 1px solid black;
					}
				}
				.image-wrapper {
					display: flex;

					flex-direction: column;
					justify-content: center;
					align-items: center;
					height: 100%;
					width: 100%;
					img {
						height: 275px;
						width: auto;
						object-fit: contain;
					}
				}
			}
		}
		.heading-wrapper {
			display: grid;
			align-items: flex-start;
			height: max-content;
			.title-wrapper {
				display: flex;
				width: 100%;
				justify-content: space-between;
				padding-bottom: 0.5rem;
				align-items: flex-start;
				.title {
					font-size: 1rem;
					padding: 0rem;
					margin: 0rem;
					border: none;
					font-family: "titillium-web", sans-serif;
					font-weight: 700;
					span {
						font-family: "titillium-web", sans-serif;
						color: $factorycatred;
						font-size: 0.85rem;
					}
				}
				button {
					display: flex;
					width: max-content;
					align-items: center;
					background: $factorycatred;
					border: none;
					cursor: pointer;
					height: 2.5rem;
					padding: 0rem 1rem;
					color: white;
					font-weight: bold;
				}
			}
			.spec-wrapper {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				width: 100%;
				justify-content: space-between;
				margin: 0rem 0rem 0.5rem 0rem;
				align-items: flex-start;
				column-gap: 0.25rem;
				.detail {
					display: grid;
					justify-content: center;
					justify-items: center;
					text-align: center;
					padding: 0.5rem;
					background: black;
					width: 100%;
					height: fit-content;
					h2 {
						font-size: 0.7rem;
						text-transform: uppercase;
						color: white;
						font-family: "titillium-web", sans-serif;
						margin: 0rem;
					}
					span {
						color: #bdbdbd;
						font-size: 0.6rem;
						font-family: "titillium-web", sans-serif;
					}
				}
			}
		}
		.details-section-wrapper {
			.description-wrapper {
				p {
				}
			}
		}
		.manuals-wrapper {
			justify-content: space-evenly;
			.manual {
				.manual-image-wrapper {
					img {
					}
				}
			}
		}
	}
}

@include responsive(medium) {
	.machine-page {
		.title {
			font-size: 1rem;
			padding: 0rem;
			margin: 0rem;
			border: none;
			font-family: "titillium-web", sans-serif;
			font-weight: 700;
			span {
				font-family: "titillium-web", sans-serif;
				color: $factorycatred;
				font-size: 0.85rem;
			}
		}
		.deck-selector-wrapper {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 1rem;
			align-items: flex-start;
			margin-top: 1rem;
			h3 {
				font-size: 0.9rem;
			}
			h4 {
				font-size: 0.8rem;
				font-weight: bold;
			}
			img {
				display: flex;
				justify-self: flex-end;
				max-width: 250px;
				object-fit: contain;
			}
			.decks-wrapper {
				display: flex;
				flex-direction: column;
				row-gap: 1rem;
				button {
					display: flex;
					align-items: center;
					justify-content: center;
					border: 1px solid black;
					height: 2rem;
					width: 85px;
					font-weight: bold;
					&.selected {
						background: black;
						color: white;
					}
					&:hover {
						border: 1px solid $factorycatred;
					}
				}
				h3 {
					text-align: left;
					padding-bottom: 0.5rem;
				}
				.scrub-systems-wrapper {
					display: flex;
					width: 100%;
					justify-content: left;
					column-gap: 1rem;
					row-gap: 1rem;
					.scrub-system {
						display: flex;
					}
				}
				.scrubdeck-sizes-wrapper {
					display: flex;
					flex-direction: column;
					width: 100%;
					.deck-sizes {
						display: flex;
						column-gap: 1rem;
						justify-content: left;
					}
				}
			}
		}
	}

	.machine-demo-request {
		display: grid;
		grid-template-columns: 2fr 3fr;
		padding: 0rem;
		.left-column,
		.right-column {
			padding: 2rem;
		}
		.left-column {
			display: grid;
			align-items: flex-start;
			align-content: flex-start;
			background: black;
			row-gap: 1rem;
			div {
				border-bottom: 1px solid #333;
				padding-bottom: 1rem;
			}
			h2,
			h3 {
				color: white;
			}
			p {
				color: #ccc;
			}
			ul {
				padding: 0.5rem 0rem;
				li {
					display: flex;
					column-gap: 1rem;
					svg {
						color: $factorycatred;
					}
				}
			}
			a {
				color: $factorycatred;
			}
			.call {
				display: grid;
				background: $factorycatred;
				color: white;
				border: none;
				width: max-content;
				padding: 1rem 3rem;
				border-radius: 4px;
			}
		}
		.right-column {
			display: grid;
		}
		.image-wrapper {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			width: 100%;
			img {
				width: 100%;
				height: auto;
				object-fit: contain;
			}
		}
	}
	.machine-page {
		display: grid;
		grid-template-columns: 1fr 1fr;
		row-gap: 1rem;
		column-gap: 1rem;
		padding: 1rem 0rem;
		align-items: flex-start;
		align-content: flex-start;
		.main-section-wrapper {
			align-items: flex-start;
			.thumbs-image-wrapper {
				display: grid;
				grid-template-columns: auto 1fr;
				width: 100%;
				.thumbnails-wrapper {
					display: flex;
					flex-direction: column;
					align-content: space-between;
					justify-content: space-between;
					width: 75px;
					row-gap: 0.25rem;
					.thumb {
						width: 75px;
						height: 75px;
						border: 1px solid black;
					}
				}
				.image-wrapper {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					height: 100%;
					width: 100%;
					img {
						height: 400px;
						width: auto;
						object-fit: contain;
					}
				}
			}
		}
		.heading-wrapper {
			display: grid;
			align-items: flex-start;
			height: max-content;
			&.mobile {
				display: none;
			}
			&.desktop {
				display: grid;
				grid-column: 1 / span 3;
			}
			.title-wrapper {
				display: flex;
				width: 100%;
				justify-content: space-between;
				padding-bottom: 0.5rem;
				align-items: flex-start;
				button {
					display: flex;
					width: max-content;
					align-items: center;
					background: $factorycatred;
					border: none;
					cursor: pointer;
					height: 2.5rem;
					padding: 0rem 1rem;
					color: white;
					font-weight: bold;
				}
			}
			.spec-wrapper {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				flex-wrap: wrap;
				width: 100%;
				justify-content: space-between;
				margin: 0rem 0rem 0.5rem 0rem;
				align-items: flex-start;
				column-gap: 0.125rem;
				row-gap: 0.125rem;
				.detail {
					display: grid;
					justify-content: center;
					justify-items: center;
					align-items: center;
					text-align: center;
					padding: 0.5rem;
					background: black;
					width: 100%;
					height: fit-content;
					h2 {
						font-size: 0.85rem;
						text-transform: uppercase;
						color: white;
						font-family: "titillium-web", sans-serif;
						margin: 0rem;
					}
					span {
						font-size: 0.8rem;
						font-family: "titillium-web", sans-serif;
					}
				}
			}
		}
		.details-section-wrapper {
			.heading-wrapper {
				align-items: center;
				.title-wrapper {
					.title {
						font-size: 1.85rem;
						line-height: 1.5rem;
						padding: 0rem;
						margin: 0rem;
						border: none;
						font-family: "titillium-web", sans-serif;
						font-weight: 700;
						span {
							font-family: "titillium-web", sans-serif;
							color: $factorycatred;
							font-size: 1.5rem;
							&.style {
								font-size: 1rem;
								color: #888;
							}
						}
					}
				}
			}
			.description-wrapper {
				p {
					line-height: 1.5rem;
				}
			}
		}

		.manuals-wrapper {
			justify-content: space-between;
			.manual {
				.manual-image-wrapper {
					img {
					}
				}
			}
		}
	}
}

@include responsive(large) {
	.machine-demo-request {
		grid-template-columns: 2fr 4fr;
		.left-column,
		.right-column {
			padding: 4rem;
			align-items: flex-start;
			align-content: flex-start;
		}
		.right-column {
			display: grid;
			align-items: flex-start;
			align-content: flex-start;
			width: 100%;
			height: max-content;
			background: #f8f8f8;
			row-gap: 1rem;
			form {
				display: grid;
				row-gap: 0.5rem;
				input,
				select,
				textarea {
					border-radius: 4px;
					background: white;
				}
				textarea {
					height: 100px;
				}
				.form-section {
					display: grid;
					grid-template-columns: 1fr 1fr;
					column-gap: 2rem;
					h3 {
						grid-column: 1 / span 2;
						border-bottom: 3px solid #ccc;
						padding-bottom: 0.5rem;
					}
				}
				.form-two-column {
					column-gap: 2rem;
				}
			}
		}
	}
}
