@import './variables.scss';

.section-wrapper {
    padding: $mobile-width-padding;
    padding-top: 1rem;
    padding-bottom: 1rem;
    h1 {
        padding-bottom: 1rem;
    }
    background-image: url('/black_diamond_plate.png');

}
.section-title {
    display: grid;
    color: white;
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 1rem;
    text-align: center;
}

.two-columns-wrapper {
    display: grid;
}
.option {
    padding: 1rem 0rem;
    &.options {
        color: white;
        h3 {
            color: $factorycatred;
        }
        p {
            color: rgba($color: #dfdfdf, $alpha: 1.0);
        }
    }

    img {
        width: 100%;
        height: max-content;
    }
}

@include responsive(small) {

}

@include responsive(medium) {
    .section-wrapper {
        padding: $notepad-width-padding;
        padding-top: 2rem;
        padding-bottom: 2rem;

    }
    .two-columns-wrapper {
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
    }
    .option {
        display: grid;
        grid-template-columns: 1fr 2fr;
        column-gap: 1rem;
        padding: 1rem;

    }
}

@include responsive(large) {
    .section-wrapper {
        padding: $desktop-width-padding;
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .two-columns-wrapper {
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
    }

}