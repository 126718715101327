@import "./variables.scss";

.request-demo-page {
	display: flex;
	flex-direction: column;
	h1 {
		margin: 0rem 0rem 0rem 0rem;
		padding: 0rem 0rem 0rem 0rem;
		height: max-content;
	}
	p {
		margin: 0rem 0rem 0rem 0rem;
		padding: 0rem 0rem 0rem 0rem;
		height: max-content;
		line-height: 1.5rem;
	}
}

@include responsive(small) {
	.request-demo-page {
		padding: $mobile-width-padding;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}

@include responsive(medium) {
	.request-demo-page {
		display: grid;
		padding: $notepad-width-padding;
		padding-top: 2rem;
		padding-bottom: 2rem;
		column-gap: 2rem;
	}
}

@include responsive(large) {
	.request-demo-page {
		display: grid;
		column-gap: 2rem;
		grid-template-columns: 3fr 4fr;
		padding: $desktop-width-padding;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}
