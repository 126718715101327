@import "../variables.scss";

.home-page-two {
	display: grid;
	align-items: flex-start;
	align-content: flex-start;
	padding-top: 4rem;
	.content-wrapper {
		display: grid;
		margin: auto;
		width: calc(100% - 1rem);
		row-gap: 0.75rem;
	}
	section {
		display: grid;
		align-items: flex-start;
		align-content: flex-start;
		width: 100%;
		padding: 1rem 0rem;
		p {
			line-height: 1.5rem;
			font-size: 1.125rem;
			padding: 0rem;
		}
		h1 {
			font-size: 2.5rem;
		}
		h2 {
			font-size: 1.75rem;
		}
		h3 {
			font-size: 1.5rem;
		}
		strong {
			color: $factorycatred;
		}
		.cta-btn {
			background: $factorycatred;
			color: white;
			padding: 1rem 2rem;
			border-radius: 0.25rem;
			border: none;
			text-align: center;
			font-weight: bold;
			cursor: pointer;
			transition: all ease-in-out 0.25s;
			width: max-content;
			&.machine {
				width: 100%;
			}
			&:hover {
				background: darken($factorycatred, 30%);
			}
		}
		&.home-page-header {
			background: black;
			h1 {
				color: white;
			}
			p {
				color: #ccc;
			}
		}
		&.black {
			background: black;
		}
		&.dark {
			background: #101010;
			color: white;
		}
		&.light-grey {
			background: #eff0f2;
		}
		&.cta-section {
			background: black;
			text-align: center;
			h2 {
				color: white;
			}
			p {
				color: #ccc;
			}
			a,
			button {
				justify-self: center;
				width: max-content;
				text-decoration: none;
			}
		}
		&.demo-request {
		}
	}
	.home-page-new-banner {
		display: flex;
		img {
			height: 260px;
			width: 100%;
			object-fit: cover;
		}
	}
	.platforms-wrapper {
		display: grid;
		width: 100%;
		row-gap: 6rem;
		.platform {
			display: grid;
			width: 100%;
			grid-template-areas: "image" "details";
			text-decoration: none;
			row-gap: 1rem;

			img {
				grid-area: image;
				width: 100%;
				height: 100%;
				transition: all ease-in-out 0.25s;
			}
			.platform-details {
				display: grid;
				row-gap: 0.75rem;
				h2 {
					color: white;
				}
				p {
					color: #ccc;
				}
			}
		}
	}
	.alt-cards-wrapper {
		display: grid;
		row-gap: 1rem;
		.alt-card {
			display: grid;
			color: #333;
			background: white;
			justify-content: center;
			justify-items: center;
			text-align: center;
			text-decoration: none;
			row-gap: 1rem;
			padding: 2rem 0rem;
			svg {
				font-size: 5rem;
			}
		}
	}
	.home-page-new-gallery {
		display: grid;
		row-gap: 1rem;
		h2 {
			padding: 0;
			text-align: center;
		}
		a {
			justify-self: center;
			width: max-content;
			text-decoration: none;
		}
		.home-page-gallery__container {
			display: grid;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
				transition: all ease-in-out 0.25s;
				&:hover {
					transform: scale(1.02);
					cursor: pointer;
				}
			}
		}
	}
	.home-page-about-wrapper {
		display: grid;
		row-gap: 1rem;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
		.content {
			display: grid;
			row-gap: 1rem;
		}
	}
}

@include responsive(small) {
	.home-page-two {
		section {
			padding: 3rem 0rem;
			&.home-page-header {
				justify-content: center;
				justify-items: center;
				text-align: center;
				button {
					justify-self: center;
					width: max-content;
				}
			}
		}
		.platforms-wrapper {
			display: grid;
			width: 100%;
			row-gap: 6rem;
			.platform {
				display: grid;
				align-items: flex-start;
				width: 100%;
				grid-template-areas: "details image";
				grid-template-columns: 2fr 1fr;
				column-gap: 2rem;
				text-decoration: none;
				row-gap: 1rem;
				img {
					display: grid;
					grid-area: image;
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
				.platform-details {
					display: grid;
					grid-area: details;
					h2 {
						color: white;
					}
					p {
						color: #ccc;
					}
				}
			}
		}
		.home-page-new-gallery {
			display: grid;
			row-gap: 1rem;
			h2 {
				padding: 0;
				text-align: center;
			}
			a {
				justify-self: center;
				width: max-content;
				text-decoration: none;
			}
			p {
				text-align: center;
			}
			.home-page-gallery__container {
				display: grid;
				grid-template-columns: 1fr 1fr;
				row-gap: 1rem;
				column-gap: 1rem;
				width: 100%;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
		}
	}
}

@include responsive(medium) {
	.home-page-two {
		section {
			padding: 2rem 0rem;
		}
		.content-wrapper {
			max-width: 1120px;
		}
		.platforms-wrapper {
			.platform {
				grid-template-columns: 1fr 1fr;
				padding: 1rem;
				transition: all ease-in-out 0.5s;
				border-radius: 4px;
				&:hover {
					background: #222;
					img {
						transform: scale(1.07);
					}
				}
			}
		}
		.alt-cards-wrapper {
			grid-template-columns: 1fr 1fr 1fr;
			.alt-card {
				background: transparent;
				padding: 4rem;
				transition: ease-in-out 0.25s;
				&:hover {
					background: white;
				}
			}
		}
		.home-page-about-wrapper {
			display: grid;
			row-gap: 1rem;
			column-gap: 4rem;
			grid-template-columns: 3fr 2fr;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
			.content {
				display: grid;
				row-gap: 1rem;
				align-items: flex-start;
				align-content: flex-start;
				a {
					height: min-content;
					text-decoration: none;
				}
			}
		}
		.home-page-new-banner {
			display: flex;
			img {
				height: 360px;
				width: 100%;
				object-fit: cover;
			}
		}
	}
}

@include responsive(large) {
	.home-page-two {
		padding-top: 3rem;
		section {
			padding: 4rem 0rem;
		}
		.content-wrapper {
			max-width: 1920px;
		}
		.platforms-wrapper {
			grid-template-columns: 1fr 1fr;
			column-gap: 6rem;
			.platform {
				grid-template-columns: 5fr 4fr;
			}
		}
		.home-page-new-gallery {
			display: grid;
			row-gap: 1rem;
			h2 {
				padding: 0;
				text-align: center;
			}
			a {
				justify-self: center;
				width: max-content;
				text-decoration: none;
			}
			p {
				text-align: center;
			}
			.home-page-gallery__container {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				row-gap: 1rem;
				column-gap: 1rem;
				width: 100%;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
		}
		.home-page-new-banner {
			display: flex;
			height: 400px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: 50% 80%;
			}
		}
	}
}
