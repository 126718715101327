@import "../variables.scss";

.home-gallery-section {
	background: #111;
}

.home-gallery-wrapper {
	// Make 2 rows 3 columns with unique sizes mirroring each other
	display: grid;
	grid-template-rows: 1fr 1fr;
	grid-template-areas:
		"gallery-1" "gallery-2" "gallery-3"
		"gallery-4" "gallery-5" "gallery-6";
	align-items: flex-start;
	align-content: flex-start;
	.gallery-section_image-container {
		overflow: hidden;
		aspect-ratio: 5 / 4;
		cursor: pointer;
		&:nth-child(1) {
			grid-area: gallery-1;
		}
		&:nth-child(2) {
			grid-area: gallery-2;
		}
		&:nth-child(3) {
			grid-area: gallery-3;
		}
		&:nth-child(4) {
			grid-area: gallery-4;
		}
		&:nth-child(5) {
			grid-area: gallery-5;
		}
		&:nth-child(6) {
			grid-area: gallery-6;
		}
		img {
			width: 100%;
			height: max-content;
			object-fit: cover;
		}
	}
}

@include responsive(small) {
	.home-gallery-wrapper {
		// Make 2 rows 3 columns with unique sizes mirroring each other
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			"gallery-1 gallery-2"
			"gallery-3 gallery-4"
			"gallery-5 gallery-6";
		row-gap: 0.5rem;
		column-gap: 0.5rem;
		padding: 0.5rem 0rem;
		.gallery-section_image-container {
			display: grid;
			img {
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

@include responsive(medium) {
}

@include responsive(large) {
	.home-gallery-wrapper {
		// Make 2 rows 3 columns with unique sizes mirroring each other
		display: grid;
		width: 75%;
		margin: auto;
		grid-template-columns: 1fr 1fr 1fr;

		grid-template-areas:
			"gallery-1 gallery-2 gallery-3"
			"gallery-4 gallery-5 gallery-6";
		.gallery-section_image-container {
			display: grid;
			img {
				object-fit: cover;
				height: 100%;
			}
		}
	}
}
