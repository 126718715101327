@import "./variables.scss";

.machine-overview {
    display: flex;
    img {
        width: 100%;
        object-fit: contain;
        height: max-content;
    }
}

@include responsive(small) {

}

@include responsive(medium) {
    
}

@include responsive(large) {
    .machine-overview {
        display: flex;
        justify-content: center;
        img {
            width: 80%;
            object-fit: contain;
            height: max-content;
        }
    }   
}