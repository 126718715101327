@import "./variables.scss";

.gallery-section {
    // Make 2 rows 3 columns with unique sizes mirroring each other
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 
        "gallery-1"  "gallery-2" "gallery-3"
        "gallery-4" "gallery-5" "gallery-6";
    padding-top: .5rem;
    background: black;
    .gallery-section_image-container {
        // Make each image a square
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        cursor: pointer;
        &:nth-child(1) {
            grid-area: gallery-1;
        }
        &:nth-child(2) {
            grid-area: gallery-2;
        }
        &:nth-child(3) {
            grid-area: gallery-3;
        }
        &:nth-child(4) {
            grid-area: gallery-4;
        }
        &:nth-child(5) {
            grid-area: gallery-5;
        }
        &:nth-child(6) {
            grid-area: gallery-6;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

@include responsive(small) {
    .gallery-section {
        // Make 2 rows 3 columns with unique sizes mirroring each other
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 
            "gallery-1 gallery-2" 
            "gallery-3 gallery-4"
            "gallery-5 gallery-6";
            padding: .5rem ;
            row-gap: .5rem;
        column-gap: .5rem;
        .gallery-section_image-container {
            display: grid;
            img {
                height: 200px;
                object-fit: cover;
            }
        }
    }
}

@include responsive(medium) {

}

@include responsive(large) {
    .gallery-section {
        // Make 2 rows 3 columns with unique sizes mirroring each other
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: 
            "gallery-1 gallery-2 gallery-3"
            "gallery-4 gallery-5 gallery-6";

        .gallery-section_image-container {
            display: grid;
            img {
                object-fit: cover;
                height: 350px;
            }
        }
    }
}