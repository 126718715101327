@import "./variables.scss";

.new-section {
	display: grid;
	padding: 1rem 0.5rem;
	padding: $mobile-width-padding;
	padding-top: 1rem;
	padding-bottom: 1rem;
	overflow: hidden;
	margin-top: 4rem;
	align-items: flex-start;
	align-content: flex-start;
	span {
		&.title {
			font-size: 2rem;
			text-align: left;
			color: #222;
			text-transform: uppercase;
			font-family: "titillium-web", sans-serif;
			font-weight: 900;
		}
	}
	.new-section-title {
		margin-bottom: 1.5rem;
		h2 {
			font-size: 1.25rem;
			text-align: left;
			color: #222;
			text-transform: uppercase;
			font-family: "titillium-web", sans-serif;
			font-weight: 900;
		}
		p {
			padding-bottom: 0rem;
		}
	}
	.new-section-tags {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		column-gap: 1rem;
		row-gap: 0.5rem;
		.tag {
			width: max-content;
			background: #333;
			color: white;
			padding: 0.5rem 1rem;
			border-radius: 5px;
			font-size: 0.85rem;
			height: max-content;
		}
	}
	.description-section {
		p {
			line-height: 1.35rem;
			&:first-of-type {
				font-weight: 400;
				padding-top: 0rem;
			}
		}
	}
	.benefits-section {
		h4 {
			padding-bottom: 0.5rem;
			text-transform: uppercase;
			letter-spacing: 0.11rem;
			color: #000;
			text-decoration: underline;
		}
		ul {
			list-style-type: none;
		}
		.heading {
			font-weight: 700;
			padding-bottom: 0.25rem;
			color: #333;
		}
		.desc {
			font-weight: 300;
			padding-bottom: 1rem;
			font-size: 0.95rem;
			color: #666;
		}
		p {
			font-weight: 400;
			width: 50%;
			font-size: 0.9rem;
			color: #666;
			padding-bottom: 0rem;
		}
	}

	.new-section-content-wrapper {
		display: grid;
		width: 100%;
		row-gap: 2rem;
	}
	.new-section-content {
		display: grid;
		row-gap: 1rem;
		align-items: flex-start;
		align-content: flex-start;
		width: 100%;
		background: white;
		padding-bottom: 2rem;
		border-bottom: 2px solid black;
	}
	h3 {
		font-size: 1.5rem;
		text-align: left;
		color: #333;
	}
	h4 {
		font-size: 1rem;
		text-align: left;
		color: #333;
	}
	p {
		width: 100%;
	}
}
.faq {
	display: grid;
	align-items: flex-start;
	align-content: flex-start;
	width: 100%;
	background: white;
	p {
		color: #333;
	}
}

@include responsive(small) {
}

@include responsive(medium) {
	.new-section {
		padding: $notepad-width-padding;
		padding-top: 2rem;
		padding-bottom: 2rem;
		.new-section-title {
			h2 {
				font-size: 2rem;
			}
		}
		.new-section-content-wrapper {
			grid-template-columns: 1fr 1fr;
			column-gap: 2rem;
		}
	}
}

@include responsive(large) {
	.new-section {
		padding: $desktop-width-padding;
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
	.new-section-content-wrapper {
		grid-template-columns: 1fr 1fr;
		column-gap: 2rem;
	}
}
