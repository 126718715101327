@import "./variables.scss";

.desktop-navigation {
	display: none;
}
.below-desktop-navigation {
	display: none;
}
.desktop_dropdown {
	display: none;
}
@include responsive(small) {
	.below-desktop-navigation {
		display: flex;
		position: fixed;
		z-index: 2;
		top: 3.5rem;
		width: 100%;
		max-width: calc(2800px - 17px);
		justify-content: flex-end;
		align-items: center;
		height: 1.5rem;
		padding: $notepad-width-padding;
		background: rgba($color: #000000, $alpha: 1);
		box-shadow: 0px 4px 8px rgba($color: #000000, $alpha: 1);
		ul {
			display: flex;
			list-style-type: none;
			li {
				display: flex;
				align-items: center;
				align-content: center;
				padding: 0rem 2rem 0rem 1rem;
				a {
					color: rgb(209, 209, 209);
					text-transform: uppercase;
					font-size: 0.7rem;
					transition: ease-in-out 100ms;
					&:hover {
						color: black;
					}
				}
			}
		}
	}
}
@include responsive(medium) {
	.below-desktop-navigation {
		padding: $desktop-width-padding;
	}
	.desktop-navigation {
		display: flex;
		align-items: center;
		position: fixed;
		z-index: 4;
		align-content: center;
		justify-content: space-between;
		height: 3.5rem;
		padding: 0rem 2rem 0rem 2rem;
		width: 100%;
		max-width: calc(2800px - 17px);
		border-bottom: 1px solid rgba($color: #000000, $alpha: 0.25);
		background: $factorycatred;
		padding: $desktop-width-padding;
		box-shadow: 0px 2px 2px rgba($color: #000000, $alpha: 0.25);
		.contact-link {
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			background: transparent;
			background: black;
			border-radius: 0.25rem;
			padding: 0.55rem 1.5rem;
			height: max-content;
			transition: ease-in-out 300ms;
			&:hover {
				background: white;
				color: black;
			}
		}
		img {
			object-fit: contain;
			width: 200px;
			height: max-content;
			cursor: pointer;
		}
		ul {
			display: flex;
			align-items: center;
			align-content: center;
			list-style-type: none;
			text-transform: uppercase;
			height: inherit;
			justify-items: right;
			justify-content: flex-end;
			width: 100%;
			li {
				display: flex;
				height: inherit;
				align-items: center;
				align-content: center;
				padding: 0rem 1rem;
				&.drop-down {
					&.hovered {
						background: white;
						a {
							color: black;
							font-weight: bold;
						}
					}
				}
				a {
					display: flex;
					height: inherit;
					align-content: center;
					align-items: center;
					width: max-content;
					text-decoration: none;
					font-size: 0.8rem;
					color: rgba($color: #ffffff, $alpha: 1);
					transition: ease-in-out 300ms;
					&:hover {
						color: black;
					}
					&.btn {
						background: #222222;
						padding: 0.5rem 1rem;
						color: white;
						border-radius: 1rem;
					}
				}
			}
		}
	}
	.desktop_dropdown {
		display: grid;
		margin: auto;
		position: fixed;
		z-index: 3;
		transform: translateY(-100%);
		transition: all ease-in-out 200ms;
		max-width: calc(2800px - 17px);
		overflow: hidden;
		width: 100%;
		height: 400px;
		&.open {
			box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.5);
			transform: translateY(0%);
			align-items: flex-start;
			top: 3.5rem;
		}
		&.close {
			transform: translateY(-180%);
			align-items: flex-start;
			box-shadow: none;
		}
	}
}

@include responsive(large) {
	.desktop_dropdown {
		height: 500px;
	}
}
