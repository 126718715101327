.dev-page {
   .navigation {
    display: flex;
    column-gap: 1rem;
    margin-bottom: 1rem;
    a {
        color: $factorycatred;
        font-weight: bold;
        background: black;
        padding: 0.5rem 1rem;
        text-decoration: none;
    }
   } 
}