@import './variables.scss';

.register-form-wrapper {
  ul {
    li {
      list-style-position: inside;
    }
  }
  display: flex;
  flex-direction: column;
  .register-form {
    display: flex;
    flex-direction: column;
    row-gap: .5rem;
    .two-column {
      padding: 0rem 0rem;
      row-gap: .75rem;
    }
    .three-column {
      display: grid;
      row-gap: .75rem;
    }
    .form-group {
      display: flex;
      flex-direction: column;
      row-gap: .25rem;
      label {
        font-size: .8rem;
        font-weight: 600;
        color: #555;
        &.error {
          color: rgb(252, 107, 59);
          font-weight: bold;
          font-size: .65rem;
          margin-left: .5rem;
        }
      }
      &.flex {
        display: grid;
        grid-template-columns: auto 1fr;
        input[type="checkbox"] {
          width: 1rem;
          height: 1rem;
          margin-right: .5rem;
        }
      }
    }
    input {
      height: 2.75rem;
      border-radius: .125rem;
      padding: .75rem;
      margin-bottom: .5rem;
      border: none;
      color: black;
      // box-shadow: inset -4px -2px 4px rgba($color: #000000, $alpha: .05);
      border: 1px solid rgba($color: #000000, $alpha: .5);
      &::placeholder {
          color: rgba(65, 65, 65, .5);
      }
  }
  select {
    height: 2.75rem;
    padding: .75rem;
  }


    button {
      width: max-content;
    }
  }
}

@include responsive(small) {
  .register-form-wrapper {
    .register-form {
      .two-column {
        display: grid;
        padding: 0rem;
        margin: 0rem;
        padding: 0rem 0rem 1rem 0rem;;
        row-gap: 1rem;
        column-gap: 1rem;
        align-items: flex-start;
        grid-template-columns: 1fr 1fr;
        .span-all {
          grid-column: 1 / 3;
        }
      }
      .three-column {
        display: grid;
        padding: 0rem;
        margin: 0rem;
        row-gap: 1rem;
        column-gap: 1rem;
        align-items: flex-start;
        padding: 0rem 0rem 1rem 0rem;;
        grid-template-columns: 1fr 1fr 1fr;
        .span-all {
          grid-column: 1 / 4;
        }
      }
    }
  }
}
@include responsive(large) {
  .register-form-wrapper {
      .register-form {
        row-gap: .5rem;
      .two-column {
        display: grid;
        padding: 0rem;
        margin: 0rem;
        padding: 0rem 0rem 1rem 0rem;;
        row-gap: 1rem;
        column-gap: 1rem;
        align-items: flex-start;
        grid-template-columns: 1fr 1fr ;
        .span-all {
          grid-column: 1 / 3;
        }
      }
      .three-column {
        display: grid;
        padding: 0rem;
        margin: 0rem;
        column-gap: 1rem;
        align-items: flex-start;
        grid-template-columns: 1fr 1fr 1fr;
        .span-all {
          grid-column: 1 / 4;
        }
      }
    }
  }
}