@import "./variables.scss";

.story-wrapper {
	display: flex;
	flex-direction: column-reverse;
	color: white;
	width: 100%;
	overflow: hidden;
	.story-text-wrapper {
		display: flex;
		flex-direction: column;
		padding: $mobile-width-padding;
		padding-top: 1rem;
		padding-bottom: 1rem;
		background: rgb(34, 34, 34);
		h2 {
			font-size: 2rem;
			color: white;
			font-family: "titillium-web", sans-serif;
			font-weight: 700;
			line-height: 1.75rem;
			padding: 0.5rem 0rem;
		}
		p {
			line-height: 1.75rem;
			font-size: 1rem;
			color: rgba($color: #ececec, $alpha: 1);
		}
		a {
			border: 2px solid white;
			background: transparent;
			color: white;
			padding: 0.5rem 1rem;
			width: max-content;
			text-decoration: none;
		}
	}
	.image-wrapper {
		width: 100%;
		img {
			height: auto;
			width: 100%;
			object-fit: contain;
		}
	}
}

@include responsive(small) {
}

@include responsive(medium) {
	.story-wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		.story-text-wrapper {
			display: grid;
			align-items: center;
			align-content: center;
			padding: $notepad-width-padding;
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}
}

@include responsive(large) {
	.story-wrapper {
		display: grid;
		grid-template-columns: 1fr 2fr;
		.story-text-wrapper {
			display: grid;
			align-items: center;
			align-content: center;
			padding: $desktop-width-padding;
		}
		.image-wrapper {
			display: grid;
			align-items: center;
			align-content: center;
			overflow: hidden;
			img {
				object-position: top;
			}
		}
	}
}
