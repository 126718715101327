@import "../variables.scss";

.home-two-popup-wrapper {
	display: grid;
	justify-items: center;
	align-items: center;
	align-content: center;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	opacity: 0;
	background: rgba(0, 0, 0, 0.75); // Semi-transparent background
	transition: opacity 0.3s ease-in-out; // Smooth fade-in/out transition
	z-index: 9999;
	// Allow all pointer events to pass through the popup
	pointer-events: none;

	&.active {
		// dont allow scrolling of the body
		opacity: 1;
		width: 100%;
		height: 100vh;
		transform: translate(0%, 0%);
		background: rgba($color: #000000, $alpha: 0.75);
		overflow: hidden;
		pointer-events: all;
	}
}

.popup-form {
	display: grid;
	background: white;
	max-width: 460px;
	width: 96%;
	border-radius: 4px;
	box-shadow: 0px 0rem 1rem rgba(0, 0, 0, 1);
	.popup-form-head {
		display: grid;
		width: 100%;
		.popup-form-head-content {
			display: flex;
			width: 100%;
			justify-content: space-between;
			padding: 1rem;
			svg {
				font-size: 1.5rem;
				cursor: pointer;
				&:hover {
					color: $factorycatred;
				}
			}
		}
		.factory-cat-logo {
			display: grid;
			justify-content: center;
			background: black;
			padding: 1rem;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
	.popup-form-body {
		display: grid;
		padding: 1rem;
	}
	.popup-form-footer {
		display: grid;
		padding: 1rem;
	}
	form {
		display: grid;
		row-gap: 1rem;
		button {
			display: grid;
			padding: 1rem;
			background: $factorycatred;
			border-radius: 4px;
			border: none;
			cursor: pointer;
			color: white;
		}
		.form-group {
			display: grid;
			label {
				font-weight: bold;
			}
			input,
			textarea {
				padding: 1rem;
				border-radius: 4px;
				// No resize
				resize: none;
				border: 1px solid black;
			}
			textarea {
				height: 150px;
			}
		}
	}
}
