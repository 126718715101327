@import "./variables.scss";

.card-section {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	background: black;
	row-gap: 1px;
	&.highlights {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 0.25rem;
		row-gap: 0.25rem;
		.card {
			display: grid;
			height: 100px;
			width: 100%;
			text-align: center;
			justify-content: center;
			align-items: center;
			align-content: center;
			h2 {
				margin-bottom: none;
			}
		}
	}
	.card {
		display: flex;
		flex-direction: column;
		color: white;
		width: 100%;
		padding: 1rem;
		background: $factorycatred;
		text-decoration: none;
		cursor: pointer;
		h2 {
			font-weight: 700;
			font-size: 1.25rem;
			color: white;
		}
		.card-section-header {
			display: flex;
			justify-content: flex-end;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			svg {
				font-size: 1.25rem;
				color: #ffffff;
			}
		}
		p {
			font-size: 1rem;
			color: #000000;
			width: 75%;
		}
	}
}
.rental-section {
	display: grid;
	background: black;
	color: white;
	padding: 1rem;
	font-size: 100%;
	text-align: center;
	justify-content: center;
	align-items: center;
	&.machine {
		background: #eee;
		color: black;
		p {
			color: black;
		}
		a {
			width: max-content;
		}
	}
	h2 {
		font-family: "titillium-web", sans-serif;
		font-size: 2rem;
	}
	a {
		display: grid;
		justify-self: center;
		color: white;
		text-decoration: none;
		padding: 0.5rem 1rem;
		background: $factorycatred;
		border-radius: 5px;
		width: max-content;
		font-weight: 600;
		transition: ease-in-out 0.2s;
		&:hover {
			background: $factorycatreddarken;
		}
	}
}

@include responsive(small) {
}

@include responsive(medium) {
	.rental-section {
		display: grid;
		background: black;
		color: white;
		padding: 2rem;
		font-size: 100%;
		text-align: center;
		justify-content: center;
		align-items: center;
		p {
			color: #ccc;
			padding-bottom: 1rem;
		}
		a {
			display: grid;
			justify-self: center;
			color: white;
			text-decoration: none;
			padding: 1rem 2rem;
			background: $factorycatred;
			border-radius: 5px;
			width: max-content;
		}
	}
}

@include responsive(large) {
	.card-section {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		flex-direction: row;
		justify-content: center;
		column-gap: 0.5rem;
		background: black;
		padding: 0.5rem 0rem;
		&.highlights {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			column-gap: 0.25rem;
			row-gap: 0.25rem;
			text-align: center;
			.card {
				align-items: center;
				align-content: center;
				padding: 6rem 0rem;
			}
		}
		.card {
			display: flex;
			flex-direction: column;
			width: 100%;
			border-bottom: none;
			padding: $desktop-width-padding;
			padding-top: 4rem;
			padding-bottom: 4rem;
			cursor: pointer;
			h2 {
				font-weight: 700;
				font-size: 2.5rem;
			}
			.card-section-header {
				display: flex;
				justify-content: flex-end;
				justify-content: space-between;
				width: 100%;
				color: black;

				svg {
					font-size: 1.25rem;
				}
			}
			&:hover {
				background: black;
				h2,
				svg {
					color: red;
				}
				p {
					color: white;
				}
			}
			p {
				color: #222;
				font-size: 1.5rem;
				width: 85%;
			}
		}
	}
	.rental-section {
		padding: 4rem;
	}
}
