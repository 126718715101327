@import "./variables.scss";

.request-a-quote-button {
	bottom: 8rem;
	background-color: #f5f5f5;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
	color: white;
	font-size: 20px;
	position: fixed;
	background: $primary-color;
	transition: all 0.3s ease;
	z-index: 5;
	text-decoration: none;
	width: max-content;
	bottom: 1.25rem;
	padding: 0.9rem 3rem;
	left: 1rem;
	border-radius: 0.25rem;
	&:hover {
		background-color: #000;
		color: #fff;
	}
}

@include responsive(small) {
	.request-a-quote-button {
	}
}
@include responsive(medium) {
	.request-a-quote-button {
		margin: auto;
		bottom: 8rem;
		background-color: #f5f5f5;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
		color: white;
		font-size: 20px;
		position: fixed;
		background: $primary-color;
		transition: all 0.3s ease;
		z-index: 5;
		text-decoration: none;
		width: max-content;
		bottom: 1.25rem;
		padding: 0.9rem 3rem;
		left: 50%;
		right: 50%;
		// absolute center
		transform: translate(-50%, 0);

		border-radius: 0.25rem;
		&:hover {
			background-color: #000;
			color: #fff;
		}
	}
}
