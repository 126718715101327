@import "./variables.scss";

.header {
    display: flex;
    position: relative;
    height: 600px;
    background: black;
    overflow: hidden;

    img {
        margin-top:3.5rem;
        height: 100%;
        opacity: 1;
        object-fit: cover;
        object-position: -300px 0px;
    }
    .text-wrapper {
        position: absolute;
        z-index: 1;
        top: 4rem;
        padding: .5rem;
        .background-wrapper {
            display: flex;
            flex-direction: column;
            background: rgba($color: #000000, $alpha: .65);
            padding: 1rem;
            row-gap: 1rem;
            .header-title {
                color: white;
                font-family: "titillium-web", sans-serif;
                font-weight: 700;
                line-height: 2.25rem;
            }
            h1 {
                color: white;
                font-family: "titillium-web", sans-serif;
                font-weight: 700;
                line-height: 2.25rem;
            }
            h2 {
                font-size: 1.25rem;
                color: rgb(236, 236, 236);
                font-family: "titillium-web", sans-serif;
                font-weight: 500;
                line-height: 1.5rem;
            }
            .link-wrapper {
                display: flex;
                flex-direction: column;
                row-gap: 1rem;
                margin-top: 1rem;
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: bold;
                    text-align: center;
                    height: 3rem;
                    font-size: .9rem;
                    text-decoration: none;
                    color: white;
                    background: rgba($color: #000000, $alpha: 1);
                    border: 2px solid white;
                    &:nth-child(even) {
                        color: black;
                        background: rgba($color: #fff, $alpha: 1);
                        border: 2px solid black;
                    }
                    &:hover {
                        color: white;
                        background: rgba($color: #000000, $alpha: 1);
                        border: 2px solid $factorycatred;
                    }
                }
            }

        }
    }
}

@include responsive(small) {
    .header {
        img {
            object-position: -150px 0px;
        }
        .text-wrapper {
            .background-wrapper {
                .link-wrapper {
                    flex-direction: row;
                    justify-content: space-between;
                    column-gap: 1rem;
                    a {
                        width: 175px;
                    }
                }
            }
        }

    }
}

@include responsive(medium) {
    .header {
        border-bottom: 1.5rem solid black;
        width: 100%;
        img {
            object-position: 0px 0px;
        }
        .text-wrapper {
            position: absolute;
            z-index: 1;
            top: 4rem;
            padding: .5rem;
            padding: 2rem;
            background: none;
            .background-wrapper {
                max-width: 85%;
                border: 1px solid $factorycatred;
                .header-title {
                    font-size: 2.5rem;
                    color: white;
                    font-family: "titillium-web", sans-serif;
                    font-weight: 700;
                    line-height: 3rem;
                    span {
                        color: $factorycatred;
                    }
                }
                h1 {
                    font-size: 2.5rem;
                    color: white;
                    font-family: "titillium-web", sans-serif;
                    font-weight: 700;
                    line-height: 3rem;
                    span {
                        color: $factorycatred;
                    }
                }
                h2 {
                    font-size: 1.25rem;
                    font-family: "titillium-web", sans-serif;
                    line-height: 1.5rem;
                    background: rgba($color: #000000, $alpha: .5);
                }
                .link-wrapper {
                    flex-direction: row;
                    justify-content: initial;
                    column-gap: 1rem;
                }
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: bold;
                    height: 3rem;
                    text-decoration: none;
                    color: white;
                    width: 225px;
                    background: rgba($color: #000000, $alpha: .5);
                    border: 2px solid white;
                    &:nth-child(even) {
                        color: black;
                        background: rgba($color: #fff, $alpha: .5);
                        border: 2px solid black;
                    }
                }
            }
        }
    }
}

@include responsive(large) {
    .header {
        display: flex;
        margin: auto;
        img {
            width: 100%;
            margin-top: 2.5rem;
            object-position: initial;
        }
        .text-wrapper {
            .background-wrapper {
                .header-title {
                    font-size: 3rem;
                    line-height: 3.5rem;
                }
                h1 {
                    font-size: 3rem;
                    line-height: 3.5rem;
                }
                h2 {
                    font-size: 1.75rem;
                }
            }

        }
    }
}





